import React from "react";
import banner from "assets/img/profile/banner.png";
import Card from "components/Card";
import { FaUserCircle } from "react-icons/fa";

interface BannerPropsType {
  employee: EmployeeType;
}

const Banner = (props: BannerPropsType) => {

  const calculateAge = (dateOfBirthString: string): number => {
    const dob = new Date(dateOfBirthString);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < dob.getDate())) {
      return age - 1;
    }
    
    return age;
  };
  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-gray-200 dark:!border-navy-700">
          {props.employee.photo ? (
            <img className="h-full w-full rounded-full" src={props.employee.photo} alt="user profile" />
          ) : (
            <FaUserCircle className="h-full w-full text-white" />
          )}
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {props.employee.first_name} {props.employee.last_name}
        </h4>
        {props.employee.designation && <p className="text-base font-normal text-gray-600">{props.employee.designation}</p>}
      </div>

      {/* Post followers */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-20">
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-navy-700 dark:text-white">{props.employee.gender}</p>
          <p className="text-sm font-normal text-gray-600">Gender</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {calculateAge(props.employee.date_of_birth)}
          </p>
          <p className="text-sm font-normal text-gray-600">Age</p>
        </div>
        
      </div>
    </Card>
  );
};

export default Banner;
