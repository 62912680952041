import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import PayslipsTable from "features/payslips/PayslipsTable";
import { useNavigate, useParams } from "react-router-dom";
import { PayslipType } from "types/payroll-types";
import { fetchEmployeeSpecificPayslipsAPI, fetchSpecificEmployeeAPI } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import { usePermissions } from "app/hooks";

type EmployeeIdType = string;

const EmployeePayslips = () => {
  const { employeeId } = useParams<EmployeeIdType>();
  const [payslips, setPayslips] = useState<PayslipType[]>([]);
  const [employee, setEmployee ] = useState<EmployeeType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const permissions = usePermissions() || [];
  const navigate = useNavigate();
  async function getEmployeeAndPayslips() {
    if (permissions && permissions.includes('payroll.view_payslip') && permissions.includes('employees.view_employee')) {
      try {
        const responseEmp: AxiosResponse<EmployeeType> = await fetchSpecificEmployeeAPI(employeeId);
        console.log("Employee : ", responseEmp.data);
        setEmployee(responseEmp.data);
        const response: AxiosResponse<PayslipType[]> = await fetchEmployeeSpecificPayslipsAPI(employeeId);
        console.log("Payslips : ", response.data);
        setPayslips(response.data);
        setIsLoading(false);
      }
      catch (error: any) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
    }
    else {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if(!employeeId.match(/\d+/)) {
      navigate('/not-found');
      return;
    }

    getEmployeeAndPayslips();
  }, [employeeId]);
  return (
    <div className="container mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {permissions && permissions.includes('payroll.view_payslip') && permissions.includes('employees.view_employee') ? (
            <>
            {error && <ErrorBox errorMessage={error} />}
            {employee && (
              <div className="mb-4 rounded-lg bg-white py-4 px-6 shadow-md dark:bg-navy-800 dark:text-white">
                <div className="flex mb-2">
                  <div className="flex-1">
                    <span className="text-md mb-1">Employee Name</span>
                  </div>
                  <div className="flex-1">
                    <span className="text-blue-400">{employee.first_name + " " + employee.last_name}</span>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="flex-1">
                    <span className="text-md mb-1">Occupation</span>
                  </div>
                  <div className="flex-1">
                    <span className="text-gray-700">{ employee.designation}</span>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="flex-1">
                    <span className="text-md mb-1">CNIC</span>
                  </div>
                  <div className="flex-1">
                    <span className="text-gray-700">{employee.CNIC}</span>
                  </div>
                </div>
                <div className="flex mb-2">
                  <div className="flex-1">
                    <span className="text-md mb-1">Gender</span>
                  </div>
                  <div className="flex-1">
                    <span className="text-gray-700">{employee.gender}</span>
                  </div>
                </div>

              </div>
            )}
            {payslips && <PayslipsTable tableData={payslips} tableName="Payslips" />}
            </>
            ) : <ErrorBox errorMessage="Insufficient privileges." /> }
          </div>
        )
      }

    </div>
  );
};
export default EmployeePayslips;