import Card from "components/Card";
import { JobOpeningType } from 'types/careers-types';
import { MdEdit } from "react-icons/md";
import Button from "components/Button";
import { Link } from "react-router-dom";

interface JobDescriptionCardPropsType {
  jobDetails: JobOpeningType;
}

const JobDescriptionCard = (props: JobDescriptionCardPropsType) => {

  return (
    <Card extra={"w-full h-full px-3 py-5"}>
    <div className="mb-8 w-full flex items-center justify-between">
      <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
      Job Information
      </h4>
      <Button type="button" varient="secondary" className="flex items-center w-max text-gray-700">
        <Link to={`/careers/job/${props.jobDetails.slug}/edit`} className="flex items-center w-max text-gray-700">
          <MdEdit className="mr-1" /> EDIT
        </Link>
      </Button>
    </div>


      {/* Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-2">
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Title</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.title}
          </p>
        </div>

        <div className="flex flex-col rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Department</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.department?.name}
          </p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Location</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.location}
          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Employement Type</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.employment_type}
          </p>
        </div>

        <div className="flex flex-col items-start  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Experience Level</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.experience_level}
          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Qualifications</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.qualifications}
          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Work Type</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.work_type}
          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Skills</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.skills_required.map(skill => skill.name).join(', ')}          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Is Active</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.is_active ? "Active" : "Inactive "}
          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Positions</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.positions}
          </p>
        </div>

        <div className="flex flex-col  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Slug</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.jobDetails.slug}
          </p>
        </div>
      </div>

      <div className="flex flex-col mt-4  rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <p className="text-sm text-gray-600">Description</p>
        <p className="text-base font-medium text-navy-700 dark:text-white">
          {props.jobDetails.description}
        </p>
      </div>
    </Card>
  );
};

export default JobDescriptionCard;
