import React, { useState } from "react";
import Card from "components/Card";
import { JobApplicantType } from "types/careers-types";
import { updateApplicantDetails } from "api/apiHelper";
import IconButton from "components/IconButton";

interface ApplicantsCardPropsType {
  applicantDetails: JobApplicantType;
}

const ApplicantCard = (props: ApplicantsCardPropsType) => {
  const [notes, setNotes] = useState(props.applicantDetails.notes);
  const [isEditing, setIsEditing] = useState(false);

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    try {
      await updateApplicantDetails(props.applicantDetails.id, { notes });
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card extra={"w-full h-full px-3 py-5"}>
      <div className="mb-8 flex w-full items-center justify-between">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Application Details
        </h4>
      </div>
      <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Title</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicantDetails.id}
          </p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Applicant name</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicantDetails.name}
          </p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Contact</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicantDetails.contact}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Email</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicantDetails.email}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">City</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicantDetails.city}
          </p>
        </div>
        <div className={`flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none col-span-2`}>
          <div className="flex items-center justify-between w-full mt-1">
            <p className="text-sm text-gray-600">Notes</p>
            {!isEditing && (
              <IconButton
                onClick={handleEditClick}
                url="#"
                name="Edit Notes"
                icon="fas fa-edit"
                extraStyles="ml-2 text-blue-500 mb-3"
              />
            )}
          </div>
          {isEditing ? (
            <>
              <textarea
                className="text-base font-medium text-navy-700 dark:text-white bg-transparent border-none focus:outline-none w-full h-24 overflow-y-auto"
                value={notes}
                onChange={handleNotesChange}
              />
              <button
                onClick={handleSaveClick}
                className="mt-2 bg-brand-500 hover:bg-brand-600 text-white px-3 py-1 rounded"
              >
                Save
              </button>
            </>
          ) : (
            <div className="text-base font-medium text-navy-700 dark:text-white w-full h-24 overflow-y-auto break-words">
              {notes}
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ApplicantCard;