import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { JobApplicantType } from 'types/careers-types';
import ApplicantCard from 'features/carrers/ApplicantCard';
import { getApplicantDetails } from 'api/apiHelper';

const Applicant = () => {
  const { applicantId } = useParams<{ applicantId: string }>();
  const [applicantDetails, setApplicantDetails] = useState<JobApplicantType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchApplicantDetails(applicantId);
  }, [applicantId]);

  const fetchApplicantDetails = async (id: string) => {
    try {
      const response = await getApplicantDetails(id);
      setApplicantDetails(response.data); 
      setIsLoading(false);
    } catch (err) {
      console.error('Failed to fetch job details:', err);
      setError('Failed to load the job details.');
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!applicantDetails) return <div>No Application details available.</div>;

  return (
    <div className="mt-5">
      <ApplicantCard applicantDetails={applicantDetails} />
    </div>
  );
};

export default Applicant;
