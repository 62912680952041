import { useState, useEffect } from "react";
import { LeaveDetailsType } from "pages/leaves/Leaves";
import { getLeaveDetails } from "api/apiHelper"
import { useNavigate, useParams } from "react-router-dom";
import LeaveInfo from "features/leaves/LeaveInfo";
import ApproveLeaveForm from "features/leaves/ApproveLeaveForm";
import { usePermissions } from "app/hooks";

const LeaveDetail = () => {
  const { leaveId } = useParams<{ leaveId: string }>();
  const [leaveDetails, setLeaveDetails] = useState<LeaveDetailsType | null>(null);
  const permissions = usePermissions() || [];
  const navigate = useNavigate();
  const fetchLeaveDetails = async (id: string) => {
    try {
      const response = await getLeaveDetails(id)
      setLeaveDetails(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(!leaveId.match(/\d+/)) {
      navigate('/not-found');
      return;
    }
    fetchLeaveDetails(leaveId)
  }, [leaveId, navigate]);

  return (
    <div className="mt-5 flex flex-col gap-4">
      <LeaveInfo leaveDetails={leaveDetails} />
      {leaveDetails?.leave_status === "P" && 
        (permissions && permissions.includes('leaves.add_employeeleavesdetails') ? 
          <ApproveLeaveForm leaveId={leaveId} leaveDetails={leaveDetails} updateLeaveDetails={fetchLeaveDetails} />
        : <p className="my-2 p-2 text-yellow-500">Insufficient permissions to Approve or Reject Leaves.</p>)
      }
    </div>
  );
};

export default LeaveDetail;
