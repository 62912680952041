import React, { useState } from "react";
import Card from "components/Card";
import IconButton from "components/IconButton";
import TextField from "components/TextField";
import {
  JobApplicationType,
  JobApplicationStatusChoicesType,
} from "types/careers-types";
import {
  updateJobApplicationDetails,
  updateJobApplicationStatus,
} from "api/apiHelper";
import SelectField from "components/SelectField";

interface JobApplicationCardPropsType {
  applicationDetails: JobApplicationType;
  availableStatuses: JobApplicationStatusChoicesType[];
}

const JobApplicationCard = (props: JobApplicationCardPropsType) => {
  const [notes, setNotes] = useState(props.applicationDetails.notes);
  const [status, setStatus] = useState(props.applicationDetails.status);
  const [isNotesEditing, setIsNotesEditing] = useState(false);
  const [isStatusEditing, setIsStatusEditing] = useState(false);

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const handleNotesSaveClick = async () => {
    try {
      await updateJobApplicationDetails(props.applicationDetails.id, { notes });
      setIsNotesEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusSaveClick = async () => {
    try {
      await updateJobApplicationStatus(props.applicationDetails.id, status);
      setIsStatusEditing(false);
    } catch (error) {
      console.log(error);
    }
  };

  const appliedAtDate = new Date(
    props.applicationDetails.applied_at
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const updatedAtDate = new Date(
    props.applicationDetails.updated_at
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Card extra={"w-full h-full px-3 py-5"}>
      <div className="mb-8 flex w-full items-center justify-between">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Application Details
        </h4>
      </div>
      <div className="grid grid-cols-1 gap-4 px-2 sm:grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Application ID</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicationDetails.id}
          </p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Applicant name</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicationDetails.applicant.name}
          </p>
        </div>

        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Contact</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicationDetails.applicant.contact}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Email</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicationDetails.applicant.email}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">City</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.applicationDetails.applicant.city}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Applied at</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {appliedAtDate}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Updated at</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {updatedAtDate}
          </p>
        </div>
        <div className="flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mb-2 text-sm text-gray-600">Resume</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            <a
              href={`${props.applicationDetails.resume}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              View Resume
            </a>
          </p>
        </div>
        {/* Status Section */}
        <div className="col-span-1 flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <div className="flex w-full justify-between">
            <p className="mb-2 text-sm text-gray-600">Status</p>
            {!isStatusEditing && (
              <IconButton
                onClick={() => setIsStatusEditing(true)}
                url="#"
                name=""
                icon="fas fa-pen"
                extraStyles="ml-2 !text-gray-700 !bg-white !p-1"
              />
            )}
          </div>
          {isStatusEditing ? (
            <div className="flex w-full flex-col items-end gap-2">
              <SelectField
                options={props.availableStatuses}
                value={status}
                onChange={(e) =>
                  setStatus(
                    e.target.value as
                      | "new"
                      | "in-review"
                      | "shortlisted"
                      | "interviewed"
                      | "offered"
                      | "rejected"
                  )
                }
                extra="w-full mt-2"
              />
              <button
                onClick={handleStatusSaveClick}
                className="mt-2 rounded bg-brand-500 px-3 py-1 text-white hover:bg-brand-600"
              >
                Save
              </button>
            </div>
          ) : (
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {status}
            </p>
          )}
        </div>
        <div className="col-span-3 flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <div className="mt-1 flex w-full items-center justify-between">
            <p className="mb-2 text-sm text-gray-600">Notes</p>
            {!isNotesEditing && (
              <IconButton
                onClick={() => setIsNotesEditing(true)}
                url="#"
                name=""
                icon="fas fa-pen"
                extraStyles="ml-2 !text-gray-700 !bg-white !p-1"
              />
            )}
          </div>
          {isNotesEditing ? (
            <div className="flex w-full flex-col items-end gap-2">
              <TextField
                id="notes"
                placeholder="Enter notes here..."
                extra="w-full mt-2"
                onChange={handleNotesChange}
                value={notes}
                disabled={false}
              />

              <button
                onClick={handleNotesSaveClick}
                className="mt-2 rounded bg-brand-500 px-3 py-1 text-white hover:bg-brand-600"
              >
                Save
              </button>
            </div>
          ) : (
            <div className="w-full break-words text-base font-medium text-navy-700 dark:text-white">
              {notes ? notes : "No notes/reviews yet"}
            </div>
          )}
        </div>
        {props.applicationDetails.cover_letter && (
          <div className="col-span-3 flex flex-col items-start rounded-2xl bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="mb-2 text-sm text-gray-600">Cover Letter</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {props.applicationDetails.cover_letter}
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default JobApplicationCard;
