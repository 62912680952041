import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSpecificEmployeeAPI, getEmployeeLeavesDetails, getEmployeeLeavesInfo } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import Banner from "components/Banner";
import General from "components/General";
import IconButton from "components/IconButton";
import { EmployeeLeaveInfoType, EmployeeLeavesDetailsType } from "types/leaves-types";
import LeavesInfo from "features/leaves/LeavesInfo";
import LeavesStatusTable from "features/leaves/LeavesStatusTable";
import { usePermissions } from "app/hooks";
import EmployeeDependentTable from "features/employees/EmployeeDependentsCard";

type EmployeeIdType = string;

const EmployeesDetails = () => {
  const { employeeId } = useParams<EmployeeIdType>();
  const [employee, setEmployee] = useState<EmployeeType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [leaveInfo, setLeaveInfo] = useState<EmployeeLeaveInfoType[] | []>([]);
  const [leavesDetail, setLeavesDetail] = useState<EmployeeLeavesDetailsType[] | []>([]);
  const permissions = usePermissions() || [];
  const navigate = useNavigate();

  async function getEmployeeDetails() {
    try {
      const response: AxiosResponse<EmployeeType> = await fetchSpecificEmployeeAPI(employeeId);
      console.log("Employees : ", response.data);
      setEmployee(response.data);
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  }

  const fetchLeaves = async (id: string) => {
    if (permissions && permissions.includes('leaves.view_employeeleaveinfo')) {
    try {
      const response = await getEmployeeLeavesInfo(id);
      setLeaveInfo(response.data);
      console.log("leaves info: ", response.data)
    } catch (error:any) {
      console.log(error);
      setError("Error: Leaves Info, " + error.message);
    }
    }

    if (permissions && permissions.includes('leaves.view_employeeleavesdetails')) {
    try {
      const response = await getEmployeeLeavesDetails(id);
      setLeavesDetail(response.data);
      console.log("leaves detail: ", response.data)
    } catch (error: any) {
      console.log(error);
      setError("Error: Leaves Details, " + error.message);
    }
    }
  };
  
  useEffect(() => {

    if(!employeeId.match(/\d+/)) {
      navigate('/not-found');
      return;
    }
    getEmployeeDetails();
    fetchLeaves(employeeId);
  }, [employeeId]);
  return (
    <div className="mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {error && <ErrorBox errorMessage={error} />}

            {employee && 
              <div className="grid justify-center grid-cols-1 gap-3">
                <div className="flex justify-end">
                  {employee.contract_id && <IconButton url={`/employee/contract/${employee.contract_id}`} name="View Contract" icon="fas fa-file-contract" extraStyles="mb-2 md:mb-0" /> }
                  {employee.id && <IconButton url={`/employee/${employee.id}/edit-bio-data`} name="Edit Bio-Data" icon="fas fa-edit" extraStyles="mb-2 md:mb-0" /> }
                </div>
                <Banner employee={employee} />
                <General employee={employee} />
              </div>
            }
            <div className="mt-5 flex flex-col gap-4">
              {permissions && permissions.includes('leaves.view_employeeleaveinfo') && 
                <LeavesInfo leaveData={leaveInfo} />
              }
              {permissions && permissions.includes('leaves.view_employeeleavesdetails') && 
                <LeavesStatusTable tableData={leavesDetail} tableHeading="Leaves Details"  />
              }
            </div>
            {permissions && permissions.includes('employees.view_my_employeedependants') && (
                <EmployeeDependentTable dependents={employee.dependents} />
            )}
          </div>
        )
      }

    </div>
  );
};
export default EmployeesDetails;