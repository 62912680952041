import React from "react";

interface ErrorMessagePropsType {
  errorMessage: any;
}

const ErrorBox = (props: ErrorMessagePropsType) => {
  const { errorMessage } = props;
  return (
    errorMessage && (
      <div className="bg-red-300 text-red-600 border-l-4 border-red-700 rounded-lg p-4 shadow-md mb-5" role="alert">
        <div className="flex items-center">
          <svg className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 4h.01M12 4.586l.707-.707a1 1 0 011.414 0l4.586 4.586a1 1 0 010 1.414l-4.586 4.586a1 1 0 01-1.414 0l-.707-.707M4.586 12l-.707.707a1 1 0 000 1.414l4.586 4.586a1 1 0 001.414 0l.707-.707M12 19.414l-.707.707a1 1 0 01-1.414 0L5.293 16.707a1 1 0 010-1.414l4.586-4.586a1 1 0 011.414 0l.707.707M19.414 12l.707-.707a1 1 0 000-1.414l-4.586-4.586a1 1 0 00-1.414 0l-.707.707"></path>
          </svg>
          <div>
            <p className="font-bold">Error:</p>
            <p>{errorMessage}</p>
          </div>
        </div>
      </div>
    )
  );
};

export default ErrorBox;
