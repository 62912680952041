import React from "react";

interface TableCellPropsType {
  children: React.ReactNode;
  className?: string;
}

const TableCell = (props: TableCellPropsType) => {
  const { children, className } = props;
  return (
    <p className={`text-sm font-semibold text-navy-700 dark:text-white min-w-[150px] border-white/0 py-3 pr-4 ${className}`}>
      {children}
    </p>
  );
}

export default TableCell;
