import TabsContainer from "components/TabsContainer";
import Tab from "components/Tab";
import JobsTab from "features/carrers/JobsTab";
import ApplicantsTab from "features/carrers/ApplicantsTab";
import JobApplicationsTab from "features/carrers/JobApplicationsTab";
import CreateJobForm from "features/carrers/CreateJobForm";
import SkillsForm from "features/carrers/SkillsForm";


const Carrers = () => {
  return (
    <div className="mt-5">
      <TabsContainer>
        <Tab isActive={true} title="Jobs" key={1}>
          <JobsTab />
        </Tab>
        <Tab isActive={false} title="Applicants" key={4}>
          <ApplicantsTab />
        </Tab>
        <Tab isActive={false} title="Job Applications" key={5}>
          <JobApplicationsTab />
        </Tab>
        <Tab isActive={false} title="Create Job" key={3}>
          <CreateJobForm />
        </Tab>
        <Tab isActive={false} title="Create Skill" key={3}>
          <SkillsForm />
        </Tab>
      </TabsContainer>
    </div>
  )
}

export default Carrers;
