import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Forbidden from "pages/errors/Unauthorized";
import MainLayout from "layouts/main";
import AuthLayout from "layouts/auth";
import RequireAuth from "./RequireAuth";
import  { userRoutes, authRoutes } from "routes";
import { filterRoutesByPermissions } from 'utils/utilityFunctions'

import { usePermissions } from "app/hooks";
import NotFound from "pages/errors/NotFound";

const App = () => {
  const permissions = usePermissions();
  const [userRoutesByPermissions, setUserRoutesByPermissions] = useState<RoutesType[]>(filterRoutesByPermissions(userRoutes, permissions));
  useEffect(()=> {
    setUserRoutesByPermissions(filterRoutesByPermissions(userRoutes, permissions));
    console.log("Permission Changed Now:");
    
  }, [permissions]);

  return (
    <Routes>
      <Route path="/forbidden" element={<Forbidden />} />
      <Route element={<AuthLayout />}>
        {authRoutes.map((route, idx) => (
          <Route path={route.path} element={route.component} key={idx} />
        ))}
      </Route>
      <Route element={<MainLayout />}>         
        {userRoutesByPermissions.map((route, idx) => (
          <Route 
            path={route.path} 
            element={
              <RequireAuth allowedPermissions={route.permissions?? []} title={route.name || "Main"} >
                {route.component}
              </RequireAuth>
            }
            key={idx}
          />
        ))}
      </Route>
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
