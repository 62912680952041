import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import { getAllEmployees } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import EmployeesListTable from "features/employees/EmployeesListTable";

const EmployeesList = () => {
  const [employees, setEmployees] = useState<EmployeeType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const getEmployeesList = async () => {
    try {
      const response: AxiosResponse<EmployeeType[]> = await getAllEmployees();
      console.log("Employees : ",   response.data);
      setEmployees(response.data);
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployeesList();
  }, []);

  return (
    <div className="container mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {error && <ErrorBox errorMessage={error} />}
            {employees && <EmployeesListTable tableData={employees} />}
          </div>
        )
      }
    </div>
  );
};

export default EmployeesList;
