import React, { useState, useEffect, ReactNode } from 'react';
import Tab, { TabPropsType } from "./Tab"

interface TabsContainerPropsType {
  children: ReactNode;
  initialActiveTab?: number;
}

const TabsContainer: React.FC<TabsContainerPropsType> = ({ children, initialActiveTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(() => {
    const storedActiveTab = localStorage.getItem('activeTab');
    return storedActiveTab ? parseInt(storedActiveTab, 10) : initialActiveTab;
  });

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab.toString());
  }, [activeTab]);

  const tabs = React.Children.toArray(children) as React.ReactElement<TabPropsType>[];

  return (
    <div className="container mx-auto">
      <div className="flex flex-wrap gap-2 rounded-xl">
        {tabs.map((tab, index) =>
          React.isValidElement<TabPropsType>(tab) && tab.type === Tab ? (
            <Tab
              key={index}
              isActive={activeTab === index}
              onClick={() => setActiveTab(index)}
              title={tab.props.title}
            />
          ) : null
        )}
      </div>

      <div className="mt-4">
        {tabs[activeTab]?.props.children}
      </div>
    </div>
  );
};

export default TabsContainer;
