
import { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import PayslipLineItemsTable from "features/payslips/PayslipLineItemsTable";

import { PayslipType } from "types/payroll-types";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import { fetchPayslipBlobAPI, fetchSpecificPayslipAPI } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import { usePermissions } from "app/hooks";

type PayslipIdType = string;


const Payslip = () => {

	const { payslipId } = useParams<PayslipIdType>();
	const navigate = useNavigate()
	const [payslip, setPayslip] = useState<PayslipType | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const permissions = usePermissions() || [];

	async function getPayslipDetails() {
		try {
			const response: AxiosResponse<PayslipType> = await fetchSpecificPayslipAPI(payslipId);
			console.log("Payslip Data: ", response.data);
			setPayslip(response.data);
			setIsLoading(false);

		} catch (error: any) {
			console.log(error);
			setIsLoading(false);
			setError(error.message);
		}
	}
	
	useEffect(() => {
		if(!payslipId.match(/\d+/)) {
			navigate('/not-found');
			return;
		}
		getPayslipDetails();
		// eslint-disable-next-line
	}, [payslipId]);
	
	

	const handleGeneratePdf = async () => {
		setIsLoading(true);
		try {
			const response = await fetchPayslipBlobAPI(payslipId);
			const url = URL.createObjectURL(response.data);
			setIsLoading(false);
			window.open(url, '_blank');

		} catch (error: any) {
			console.error('Error generating PDF:', error);
			setIsLoading(false);
			setError(error.message);
		}
	}
	

	return (
		<div className="container mt-5">
			{isLoading ? (
				<Backdrop show={isLoading} />
			) : (
				<div>
					{error && (<ErrorBox errorMessage={error} />)}
					{payslip && (
						<div>
							<button onClick={() => handleGeneratePdf()} className="bg-brand-500 m-2 flex flex-row items-center gap-2 uppercase hover:bg-white hover:text-brand-400 dark:bg-brand-400 dark:hover:bg-brand-500  rounded-lg py-2 px-3 text-[0.8rem] font-bold  text-white transition duration-200">
							<i className="fas fa-file-pdf" aria-hidden="true"></i>
								Generate Pdf
							</button>
							<div className="mb-4 rounded-lg bg-white py-4 px-6 shadow-md dark:bg-navy-800 dark:text-white">
								<div className="flex mb-2">
									<div className="flex-1">
										<span className="text-md mb-1">Employee Name</span>
									</div>
									<div className="flex-1">
										{permissions && permissions.includes('payroll.view_payslip') && permissions.includes('employees.view_employee') ?
											<Link to={payslip?.employee_details?.id ? `/payslip/emp/${payslip?.employee_details?.id}` : ""}>
												<span className="text-blue-400">{payslip.employee_details && payslip.employee_details.first_name + " " + payslip.employee_details.last_name}</span>
											</Link>
											:
											<span className="text-blue-400">{payslip.employee_details && payslip.employee_details.first_name + " " + payslip.employee_details.last_name}</span>
										}
									</div>
								</div>
								<div className="flex mb-2">
									<div className="flex-1">
										<span className="text-md mb-1">Designation</span>
									</div>
									<div className="flex-1">
										<span className="text-gray-700">{payslip.employee_details && payslip.employee_details.designation}</span>
									</div>
								</div>
								<div className="flex mb-2">
									<div className="flex-1">
										<span className="text-md mb-1">Period Start</span>
									</div>
									<div className="flex-1">
										<span className="text-gray-700">{payslip.period_start}</span>
									</div>
								</div>
								<div className="flex mb-2">
									<div className="flex-1">
										<span className="text-md mb-1">Period End</span>
									</div>
									<div className="flex-1">
										<span className="text-gray-700">{payslip.period_end}</span>
									</div>
								</div>

								<div className="flex mb-2">
									<div className="flex-1">
										<span className="text-md mb-1">Status</span>
									</div>
									<div className="flex-1">
										<span className="text-white rounded-lg bg-yellow-500 p-1 px-2">{payslip.status}</span>
									</div>
								</div>

							</div>
							{payslip.payslip_line_items && <PayslipLineItemsTable tableData={payslip.payslip_line_items} tableName={"Payslip Line Items"} />}
						</div>
					)}
				</div>
			)
			}
		</div>
	)
}

export default Payslip;