import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import PayslipsTable from "features/payslips/PayslipsTable";
import { PayslipType } from "types/payroll-types";
import { fetchMyPayslipsAPI } from "api/apiHelper";

const MyPayslips = () => {
  const [payslips, setPayslips] = useState<PayslipType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const  getMyPayslips = async() => {
    try {
      const response: AxiosResponse<PayslipType[]> = await fetchMyPayslipsAPI();
      console.log("My Payslips : ", response.data);
      setPayslips(response.data);
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getMyPayslips();
  }, []);
  return (
    <div className="container mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {error && <p className="text-red-500">{error}</p>}
            
            {payslips && <PayslipsTable tableData={payslips} tableName="Payslips" ownPayslips={true} />}
          </div>
        )
      }

    </div>
  );
};
export default MyPayslips;