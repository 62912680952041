import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import Backdrop from "components/Backdrop";
import { type PayslipType, type PayslipItemTypeType } from "types/payroll-types";
import { fetchEmployeesWithIncomeAPI, fetchNonAutoPayslipLineItemTypesAPI, payslipCreateAPI } from "api/apiHelper";
import ErrorBox from "components/ErrorBox";
import { toast } from "react-toastify";
import { usePermissions } from "app/hooks";

const CreatePayslip = () => {
  const navigate: NavigateFunction = useNavigate();
  
  const [employees, setEmployees] = useState<EmployeeType[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [payslip, setPayslip] = useState<PayslipType>({
    period_start: "",
    period_end: "",
    employee: "",
    payslip_line_items: [],
  });
  const permissions = usePermissions() || [];

  async function fetchEmployees() {
    if(permissions && permissions.includes('payroll.view_paysliplineitemtype')) {
      try {
        const response: AxiosResponse<EmployeeType[]> = await fetchEmployeesWithIncomeAPI();
        console.log("All Employees: ", response.data);
        let emps: EmployeeType[] = response.data;
        emps.unshift({
          id: "",
          CNIC: "",
          address: "",
          company: "",
          date_of_birth: "",
          first_name: "None",
          gender: "M",
          last_name: "",
          designation: "",
          phone: "",
          photo: null,
          total_income: 0,
          user: null,
          date_joined: null,
        });
        console.log(emps);
        setEmployees(emps);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
    }
  }
  async function fetchLineItemTypes() {
    if(permissions && permissions.includes('payroll.view_paysliplineitemtype')) {
      try {
        const response: AxiosResponse<PayslipItemTypeType[]> = await fetchNonAutoPayslipLineItemTypesAPI();
        console.log("Payslip Items: ", response.data);
        const lineItemTypesWithAmount: PayslipItemTypeType[] = response.data.map((item: PayslipItemTypeType) => ({
          ...item,
          amount: 0
        }));
        setPayslip({
          ...payslip,
          payslip_line_items: lineItemTypesWithAmount,
        });

      } catch (error: any) {
        console.log(error);
        setError(error.message);
      }
    }
  }
  
  useEffect(() => {
    
    fetchEmployees();
    fetchLineItemTypes();
  }, []);

  const handleCreatePayslip = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    const notification = toast.loading("Creating Payslip...");
    setIsLoading(true);
    try {
      const response: AxiosResponse<PayslipType> = await payslipCreateAPI(payslip);
      console.log(response.data);
      setIsLoading(false);
      toast.update(notification, {
        render: "Payslip created successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      navigate(response.data?.id ? `/payslip/${response.data.id}` : "/payslip/");
    } catch (error: any) {
      console.log(error);
      setError(error.message);
      toast.update(notification, {
        render: "Failed to create payslip.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  }

  const handleItemTypeChange = (typeId: string, value: string) => {
    const newValue: number = isNaN(parseFloat(value)) ? 0 : parseFloat(value);

    const updatedPayslipItems: PayslipItemTypeType[] = payslip.payslip_line_items.map((item: PayslipItemTypeType) => {
      if (item.id === typeId) {
        return { ...item, amount: newValue };
      }
      return item;
    });

    setPayslip({ ...payslip, payslip_line_items: updatedPayslipItems });
  };

  return (
    <div className="container mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <>
            {error && <ErrorBox errorMessage={error} />}
            {permissions && permissions.includes('payroll.view_paysliplineitemtype') && permissions.includes('employees.view_employee') ? (
              employees ? (
              <form onSubmit={handleCreatePayslip} className="mx-auto md:max-w-md lg:max-w-2xl">
                <div className="container mb-4 rounded-lg bg-white dark:bg-navy-800 py-4 px-6 shadow-md dark:text-white">
                  <h2 className="mb-4 text-center dark:text-white text-navy-700 text-2xl font-semibold">
                    Payslip Details
                  </h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 mb-2 gap-4">
                    <label htmlFor="period_start" className="mb-2 block ">
                      Period Start
                    </label>
                    <input
                      type="date"
                      name="period_start"
                      id="period_start"
                      value={payslip.period_start}
                      onChange={(e) =>
                        setPayslip({ ...payslip, period_start: e.target.value })
                      }
                      required
                      className="mt-1 ml-4 rounded border px-3 py-2 dark:bg-navy-700 focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 mb-2 gap-4">
                    <label htmlFor="period_end" className="mb-2 block ">
                      Period End
                    </label>
                    <input
                      type="date"
                      name="period_end"
                      id="period_end"
                      value={payslip.period_end}
                      onChange={(e) =>
                        setPayslip({ ...payslip, period_end: e.target.value })
                      }
                      required
                      className="mt-1 ml-4 rounded border px-3 py-2 dark:bg-navy-700 focus:border-blue-300 focus:outline-none focus:ring"
                    />
                  </div>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 mb-2 gap-4">
                    <label htmlFor="employee" className="mb-2 block ">
                      Employee
                    </label>
                    <select
                      name="employee"
                      id="employee"
                      value={payslip.employee}
                      onChange={(e) => {
                        setPayslip({ ...payslip, employee: e.target.value });
                      }}
                      required
                      className="mt-1 ml-4 rounded border px-3 py-2 md:max-w-100 dark:bg-navy-700 focus:border-blue-300 focus:outline-none focus:ring"
                    >
                      {employees.map((employee: EmployeeType) => <option key={employee.id} value={employee.id}>
                        {`${employee.first_name} ${employee.last_name} - ${employee.designation}`}
                      </option>)}
                    </select>
                  </div>
                </div>
                <div className="rounded-lg bg-white p-4 shadow-md dark:bg-navy-800 dark:text-white">
                  {payslip.payslip_line_items &&
                    payslip.payslip_line_items.length > 0 && (
                      <div>
                        <h2 className="mb-4 text-center text-2xl dark:text-white text-navy-700 font-semibold ">
                          Payslip Line Items
                        </h2>
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th className="px-3 py-2 ">ID</th>
                              <th className="px-3 py-2 text-left ">Description</th>
                              <th className="px-3 py-2 text-left ">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {payslip.payslip_line_items.map((itemType) => (
                              <tr key={itemType.id}>
                                <td className="px-3 py-2 text-center ">
                                  {itemType.id}
                                </td>
                                <td className="px-3 py-2 ">{itemType.description}</td>
                                <td className="px-3 py-2 ">
                                  <input
                                    type="number"
                                    step="0.01"
                                    value={
                                      payslip.payslip_line_items.find(
                                        (item) => item.id === itemType.id
                                      )?.amount || ""
                                    }
                                    onChange={(e) =>
                                      handleItemTypeChange(
                                        itemType.id,
                                        e.target.value
                                      )
                                    }
                                    className="rounded border dark:bg-navy-700 bg-gray-100 px-3 py-2 w-full focus:border-blue-300 focus:outline-none focus:ring"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      // </div>
                    )}
                </div>

                <div className="mt-4 flex justify-center">
                  <button
                    type="submit"
                    className="px-4 py-2 bg-brand-500 text-white rounded-md dark:bg-brand-400 dark:hover:bg-brand-500 hover:bg-brand-700"

                  >
                    Create Payslip
                  </button>
                </div>
              </form>
            ) : (
              <ErrorBox errorMessage="No Employees available."/>
            )
            ) :
            <ErrorBox errorMessage="Insufficient privileges."/>
            }
          </>
        )}
    </div>
  );
};

export default CreatePayslip;
