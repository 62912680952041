import { createSlice } from "@reduxjs/toolkit";
import { getLocalStoragePermissions } from "utils/utilityFunctions";

interface AuthStateType {
  accessToken: string;
  permissions: UserPermissionType[];
  loading: boolean;
  loginError: any;
}
export const initialState: AuthStateType = {
  accessToken: "",
  permissions: getLocalStoragePermissions(),
  loading: false,
  loginError: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(state, action) {
      state.loginError = initialState.loginError;
      state.accessToken = action.payload.token;
      state.permissions = action.payload.permissions;
    },
    loginFailure(state, action) {
      state.accessToken = initialState.accessToken;
      state.permissions = initialState.permissions;
      state.loginError = action.payload;
    },
    setAuthLoading(state, action) {
      state.loading = action.payload
    }
}});

export const { loginSuccess, loginFailure, setAuthLoading } = authSlice.actions;

export default authSlice.reducer;
