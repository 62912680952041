import { useState, useEffect } from 'react';
import AllFormsTable from 'features/appraisals/AllAppraislasTable';
import { getAssignedFormsForUser } from 'api/apiHelper';
import { getCompletedFormsForUser } from 'api/apiHelper';
import TabsContainer from "components/TabsContainer";
import Tab from "components/Tab"; 

export interface FormType {
  id: number; 
  title: string;
  description: string; 
  created_by: number;
  created_at: string;
  updated_at: string;
}

const Appraisals = () => {
  const [assignedForms, setAssignedForms] = useState<FormType[] | []>([]);
  const [completedForms, setCompletedForms] = useState<FormType[] | []>([]);

  const fetchAssignedForms = async () => {
    try {
      const response = await getAssignedFormsForUser();
      setAssignedForms(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchCompletedForms = async () => {
    try {
      const response = await getCompletedFormsForUser();
      setCompletedForms(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchAssignedForms();
    fetchCompletedForms();
  }, []);

  return (
    <div className="mt-5">
      <TabsContainer>
        <Tab isActive={true} title="Assigned" key={1}>
          <AllFormsTable title="All Forms" tableData={assignedForms} key={1} />
         </Tab>
        <Tab isActive={false} title="Completed" key={2}>
          <AllFormsTable title="Completed Forms" tableData={completedForms} key={2} />
         </Tab>
      </TabsContainer>
    </div>
  );
};

export default Appraisals;
