import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import BatchesDataTable from "features/payslips/BatchesDataTable";
import Backdrop from "components/Backdrop";

import IconButton from "components/IconButton";
import { fetchAllBatchesAPI } from "api/apiHelper";
import { BatchType } from "types/payroll-types";
import { usePermissions } from "app/hooks";
import ErrorBox from "components/ErrorBox";

const PayslipBatches = () => {
  const [batches, setBatches] = useState<BatchType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const permissions = usePermissions() || [];

  const  getPayslipsBatches = async () => {
    try {
      const response: AxiosResponse<BatchType[]> = await fetchAllBatchesAPI();
      console.log("Payslips : ", response.data);
      setBatches(response.data);
      setIsLoading(false);
    }
    catch (error: any) {
      console.log(error);
      setError(error.message);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    
    getPayslipsBatches();
  }, []);
  return (
    <div className="container mt-5">
      {
        isLoading ? (
          <Backdrop show={isLoading} />

        ) : (
          <div>
            {error && <ErrorBox errorMessage={error} />}

            <div className="flex md:flex-row md:justify-between items-center mb-4">
              <div className="flex flex-wrap justify-start">
                {permissions && permissions.includes('payroll.view_payslip') &&
                  <IconButton url="/payslip/" name="Payslips" icon="fas fa-tasks" extraStyles="mb-2 md:mb-0" />
                }
              </div>
              <div className="flex flex-wrap justify-end md:justify-end">
                {permissions && permissions.includes('payroll.add_payslipbatch') &&
                  <IconButton url="/payslip/batch/create" name="Create Batch" icon="fas fa-plus" extraStyles="mb-2 md:mb-0" />
                }
              </div>
            </div>
            {batches && <BatchesDataTable tableData={batches} tableName="Batches" />}
          </div>
        )
      }

    </div>
  );
};
export default PayslipBatches;
