
import { useEffect, useState } from "react";
import CreateBatchPayslipTable1 from "features/payslips/CreateBatchPayslipTable1";
import CreateBatchPayslipTable2 from "features/payslips/CreateBatchPayslipTable2";
import { AxiosResponse } from "axios";
import { PayslipLineItemType, ColumnDataType, PayslipItemTypeType, BatchType } from "types/payroll-types";
import { fetchEmployeesWithIncomeAPI, fetchNonAutoPayslipLineItemTypesAPI } from "api/apiHelper";
import Backdrop from "components/Backdrop";
import ErrorBox from "components/ErrorBox";
import { usePermissions } from "app/hooks";

const CreatePayslipBatch = (): JSX.Element => {
  const [employees, setEmployees] = useState<EmployeeType[] | null>(null);
  const [batchStart, setBatchStart] = useState<BatchType["period_start"]>("");
  const [batchEnd, setBatchEnd] = useState<BatchType["period_end"]>("");
  const [showSelectEmployeesTable, setShowSelectEmployeesTable] = useState<Boolean>(true);
  const [selectedEmployees, setSelectedEmployees] = useState<EmployeeType[]>([]);
  const [typeHeadings, setTypeHeadings] = useState<ColumnDataType[]>([]);
  const [lineItemTypes, setLineItemTypes] = useState<PayslipLineItemType[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");
  const permissions = usePermissions() || [];

  async function fetchEmployees() {
    if(permissions && permissions.includes('employees.view_employee') ){
      try {
        const response:AxiosResponse<EmployeeType[]> = await fetchEmployeesWithIncomeAPI();
        console.log("All Employees: ", response.data);
        let emps:EmployeeType[] = response.data;
        console.log(emps);
        setEmployees(emps);
        setIsLoading(false);
      } catch (error:any) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
    }
    else {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    
    fetchEmployees();
  }, []);
  async function fetchLineItemTypes() {
    if(permissions && permissions.includes('payroll.view_paysliplineitemtype')){
      try {
        const response:AxiosResponse<PayslipItemTypeType[]> = await fetchNonAutoPayslipLineItemTypesAPI();
        console.log("Payslip Items: ", response.data);
        setLineItemTypes(response.data);
        let headings: ColumnDataType[] = [];
        response.data.forEach((type: PayslipItemTypeType) => {
          headings.push({
            Header: type.description,
            accessor:"",
          })
        });
        setTypeHeadings(headings);
      } catch (error:any) {
        console.log(error);
        setError(error.message);
      }
    }
    else {
      setIsLoading(false);
    }
  }
  function finalizeSelectedEmployees(emps: EmployeeType['id'][], periodStart:BatchType["period_start"] , periodEnd: BatchType["period_end"]) {
    const selected_emps_data:EmployeeType[] = employees.filter((employee: EmployeeType) => emps.includes(employee.id));
    setSelectedEmployees(selected_emps_data);
    setBatchStart(periodStart);
    setBatchEnd(periodEnd);
    setShowSelectEmployeesTable(false);
    fetchLineItemTypes();
    
  }
  

  return (
    <div className="container mt-4">
      {isLoading ? (
        <Backdrop show={isLoading} />
      ) : error ? (
        <ErrorBox errorMessage={error} />
      ) : (
        permissions && permissions.includes('employees.view_employee') && permissions.includes('payroll.view_paysliplineitemtype') &&
        permissions.includes('payroll.add_payslipbatch')?( employees && 
        <div>
          {showSelectEmployeesTable ? (
            <CreateBatchPayslipTable1 tableData={employees} finalizeSelectedEmployees={finalizeSelectedEmployees} />
          ): (
            <div>
              {
                selectedEmployees && selectedEmployees.length > 0 && lineItemTypes && lineItemTypes.length > 0 &&
                <CreateBatchPayslipTable2 columnsData={typeHeadings} tableData={selectedEmployees} ItemTypes={lineItemTypes} batchStart={batchStart} batchEnd={batchEnd} tableName="Payslips Line Items"/>
              }
            </div>
          )}
        </div>
      ):(
        <ErrorBox errorMessage="Insufficient privileges." />
      ))}
    </div>
  );
};

export default CreatePayslipBatch;
