import EmployeeBioDataForm from "features/employees/EmployeeBioDataForm";

const EmployeeBioData = () => {
  return (
    <div className="mt-5">
      <EmployeeBioDataForm />
    </div>
  );
}

export default EmployeeBioData;
