import axios from "axios";

import * as url from "./urls";
import { authHeader, logout } from "utils/utilityFunctions";

const API_URL = url.BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json"
  },
});

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && (error.response.data?.detail === "Token has expired" || error.response.data?.detail === "Invalid token")) {
      return logout();
    } else if (error.response && error.response.status === 401 && error.response.data.redirect) {
      window.location.href = '/bio-data';
      return Promise.reject(error); 
    } else {
      return Promise.reject(error); 
    }
  }
);

axiosApi.defaults.headers.common.Authorization = authHeader()?.Authorization;

// timeout in miliseconds of the requests
axiosApi.defaults.timeout = 300000;

class ApiCore {
  /**
   * Fetches data from given url
   */
  get = async (url: string, config: any = {}) => {
    axiosApi.defaults.headers.common.Authorization = authHeader()?.Authorization;
    // params = {...params, "cancelToken":source.token}
    return await axiosApi.get(url, config);
  };
  
  /**
   * post given data to url
   */
  post = async (url: string, data: any = {}, config: any = {}) => {
    axiosApi.defaults.headers.common.Authorization = authHeader()?.Authorization;
    return await axiosApi.post(url, data, config);
  };

  /**
   * Updates data
   */
  update = async (url: string, data: any = {}, config: any = {}) => {
    axiosApi.defaults.headers.common.Authorization = authHeader()?.Authorization;
    return await axiosApi.put(url, data, config);
  };

  /**
   * Delete
   */
  delete = async (url: string, data: any = {}) => {
    axiosApi.defaults.headers.common.Authorization = authHeader()?.Authorization;
    return await axiosApi.delete(url, { data });
  };

  cancel = (axiosSource: any) => {
    axiosSource.cancel();
  };
  getBlob = async (url: string, config: any = {}) => {
    axiosApi.defaults.headers.common.Authorization = authHeader()?.Authorization;

    const mergedConfig = {
      ...config,
      responseType: 'blob'
    };
    return await axiosApi.get(url, mergedConfig);
  };
}

export { ApiCore };
