import { combineReducers, configureStore } from '@reduxjs/toolkit'
import auth from "./slices/Auth/reducer"
import emp from "./slices/Employee/reducer";

const rootreducer = combineReducers({
  auth,
  emp,
})

export const store = configureStore({
  reducer: rootreducer, devTools: true
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
