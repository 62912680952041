import { useState } from "react";
import Checkbox from "components/Checkbox";
import Card from "components/Card";
import { toast } from "react-toastify";
import { formatAmount } from "utils/utilityFunctions";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { type BatchType} from "types/payroll-types";

type RowObj = EmployeeType
type FinalizeSelectedEmployeesType = (selectedIds: EmployeeType["id"][], periodStart: BatchType["period_start"], periodEnd: BatchType["period_end"]) => void;
interface CreateBatchPayslipTable1Type {
  tableData: EmployeeType[];
  finalizeSelectedEmployees: FinalizeSelectedEmployeesType
}
function CreateBatchPayslipTable1(props: CreateBatchPayslipTable1Type) {

  const { tableData, finalizeSelectedEmployees } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [periodStart, setPeriodStart] = useState<string>("");
  const [periodEnd, setPeriodEnd] = useState<string>("");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">FIRST NAME</p>
      ),
      cell: (info: any) => (
        <div className="flex items-center">
          <Checkbox
             checked={selectedRows.includes(info.row.original.id)}
             onChange={() => handleCheckboxChange(info.row)}
            // colorScheme="brandScheme"
            me="10px"
          />
          <p className="ml-3 text-sm font-bold text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          LAST NAME
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("designation", {
      id: "designation",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">
          DESIGNATION
        </p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("company", {
      id: "company",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">COMPANY</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("total_income", {
      id: "total_income",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white">TOTAL INCOME</p>
      ),
      cell: (info) => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {formatAmount(info.getValue())}
        </p>
      ),
    }),
  ];
  const [data, setData] = useState<RowObj[]>(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleCheckboxChange = (row: any) => {
    const isSelected:Boolean = selectedRows.includes(row.original.id);

    if (isSelected) {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((id:string) => id !== row.original.id)
      );
    } else {
      setSelectedRows((prevSelectedRows:string[]) => [...prevSelectedRows, row.original.id]);
    }
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds:string[] = data.map((employee: EmployeeType) => employee.id);
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const handleCreateBatchButtonClick = () => {
    const notification = toast.loading("Proceeding...");
    if (!periodStart || !periodEnd) {
      toast.update(notification, { render: "Period Start and End are required!", type: "error", isLoading: false, autoClose: 5000});
      return;
    }
    if (selectedRows.length < 1) {
      toast.update(notification, { render: "Atleast one Employee should be selected!", type: "error", isLoading: false, autoClose: 5000});
      return;
    }
    console.log("Selected Rows: ", selectedRows);
    toast.update(notification, { render: "", type: "success", isLoading: false, autoClose: 100});
    finalizeSelectedEmployees(selectedRows, periodStart, periodEnd);

  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Select Employees
        </div>

        <div className="mb-2">
          <button
            className={`rounded-lg text-white px-4 py-2 mr-2 ${ selectAll? "bg-gray-500" : "bg-brand-500 dark:bg-brand-400"}`}
            onClick={handleSelectAll}
          >
            {selectAll ? "Unselect All" : "Select All"}
          </button>
        </div>
      </header>
      <div className="flex justify-center align-middle my-4">
        <label htmlFor="period_start" className="mt-3 text-s font-medium text-gray-700 dark:text-white">Period Start</label>
        <input
          type="date"
          id="period_start"
          name="period_start"
          value={periodStart}
          onChange={(e) =>
            setPeriodStart(e.target.value)
          }
          required
          className="mt-1 mx-4 rounded border px-3 py-2 dark:bg-navy-700 focus:border-blue-300 focus:outline-none focus:ring"
        />
        <label htmlFor="period_end" className="mt-3 text-s font-medium text-gray-700 dark:text-white">Period End</label>
        <input
          type="date"
          id="period_end"
          name="period_end"
          value={periodEnd}
          onChange={(e) =>
            setPeriodEnd(e.target.value)
          }
          required
          className="mt-1 ml-4 rounded border px-3 py-2 dark:bg-navy-700 focus:border-blue-300 focus:outline-none focus:ring"
        />
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="cursor-pointer border-b-[1px] border-gray-200 pt-4 pb-2 pr-4 text-start"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="min-w-[150px] border-white/0 py-3  pr-4"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      
      <div className="my-4">
        <button
          className="px-4 py-2 bg-brand-500 text-white rounded-md dark:bg-brand-400 dark:hover:bg-brand-500 hover:bg-brand-700"
          onClick={handleCreateBatchButtonClick}
        >Create Batch</button>
      </div>
    </Card>
  );
}

export default CreateBatchPayslipTable1;
const columnHelper = createColumnHelper<RowObj>();
