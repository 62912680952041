import { Link } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { BatchTablePayslipType } from "types/payroll-types";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import Table from "components/Table";
import { formatAmount } from "utils/utilityFunctions";

interface PayslipBatchDataTableType {
  tableData: BatchTablePayslipType[];
  tableName: string;
  batchId: number;
}

function PayslipBatchDataTable(props: PayslipBatchDataTableType) {
  const { tableData, tableName, batchId } = props;

  const columnHelper = createColumnHelper<BatchTablePayslipType>();
  const columns:ColumnDef<BatchTablePayslipType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info) => (
        <Link to={`/payslip/batch/${batchId}/${info.row.original.id}`} className="text-sm underline font-bold text-blue-700 dark:text-white">
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <TableHeader>Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("total_income", {
      id: "total_income",
      header: () => (
        <TableHeader>Total Income</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {formatAmount(info.getValue())}
        </TableCell>
      ),
    }),
    columnHelper.accessor("net_salary", {
      id: "net_salary",
      header: () => (
        <TableHeader>Net Salary</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {formatAmount(info.getValue())}
        </TableCell>
      ),
    }),
    
  ];
  return (
    <Table<BatchTablePayslipType> tableData={tableData} columns={columns} tableHeading={tableName} />
  )
  
}

export default PayslipBatchDataTable;
