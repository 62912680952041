import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClauseType } from "features/contracts/CreateContract";
import { AxiosResponse } from "axios";
import { getContractAPI } from "api/apiHelper";
import Backdrop from "components/Backdrop";
import ErrorBox from "components/ErrorBox";
import Card from "components/Card";

type ContractIdType = string;

export interface ContractType {
  id: string,
  clauses_in_contract: ClauseType[],
  employee: EmployeeType['id'];
}

const ShowContract = () => {
  const { contractId } = useParams<ContractIdType>();
  const [contract, setContract] = useState<ContractType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const navigate = useNavigate();

  async function getContractDetails(id: string) {
		try {
			const response: AxiosResponse<ContractType> = await getContractAPI(id);
			console.log("Contract Data: ", response.data);
			setContract(response.data);
			setIsLoading(false);

		} catch (error: any) {
			console.log(error);
			setIsLoading(false);
			setError(error.message);
		}
	}

	useEffect(() => {
    if(!contractId.match(/\d+/)) {
      navigate('/not-found');
      return;
    }
		getContractDetails(contractId);
	}, [contractId, navigate]);
  
  return (
    <div className="mt-5">
      {isLoading ? (
				<Backdrop show={isLoading} />
			) : (
        <div className="">
          {error && <ErrorBox errorMessage={error} />}
          { contract && 
            <Card>
              <div className="p-5">
                <h4 className="text-2xl font-bold text-center text-navy-700 dark:text-white mb-3 p-2">Contract Clauses</h4>
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="border-b border-gray-200 text-navy-700 dark:text-white">
                    <tr>
                      <th className="py-3 px-6 text-left text-base font-bold ">No.</th>
                      <th className="py-3 px-6 text-left text-base font-bold ">Legal Text</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {contract.clauses_in_contract.map((item, index) => (
                      <tr key={index} className="">
                        <td className="py-4 px-6 ">{index+1}</td>
                        <td className="py-4 px-6  text-gray-600">{item.legal_text}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card>
          }
        </div>
      )}
    </div>
  );
};

export default ShowContract;
