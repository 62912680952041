import { useState } from "react";
import Button from "components/Button";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import DateRangePicker from "components/DateRangePicker";
import {
  fetchPayrollReportsAPI,
  fetchPayrollTaxReportAPI,
} from "api/apiHelper";
import { MdFileDownload } from "react-icons/md";

interface DownloadReportsModalPropsType {
  open: boolean;
  onClose: () => void;
}

const DownloadReportsModal = (props: DownloadReportsModalPropsType) => {
  const { open, onClose } = props;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const handleDateChange = (start: Date, end: Date) => {
    setStartDate(start);
    setEndDate(end);
  };

  const getReports = async () => {
    try {
      const response = await fetchPayrollReportsAPI(startDate, endDate);

      const blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "reports.zip";
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading reports:", error);
    }
  };

  const getTaxReport = async () => {
    try {
      const response = await fetchPayrollTaxReportAPI(startDate, endDate);

      const blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "tax_report.xlsx";
      document.body.appendChild(a);
      a.click();

      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading tax report:", error);
    }
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay className="!z-50 bg-gray-700/20" />
      <ModalContent
        className="z-50 flex h-screen w-full items-center justify-center p-4"
        containerProps={{
          zIndex: "2000",
        }}
      >
        <ModalBody className="flex w-max flex-col rounded-lg bg-white p-4">
          <ModalHeader className="text-xl font-bold text-brand-950 dark:text-white mb-4">
            Download Reports
          </ModalHeader>

          <DateRangePicker onDateChange={handleDateChange} />

          <ModalFooter className="flex justify-end gap-2">
            <Button varient="secondary" onClick={(e: any) => onClose()}>
              Cancel
            </Button>
            <Button
              varient="primary"
              onClick={(e: any) => getReports()}
              className="!bg-brand-500 hover:!bg-brand-600 active:!bg-brand-700 disabled:!bg-gray-200 flex items-center gap-2"
            >
              <MdFileDownload />
              Reports
            </Button>
            <Button
              varient="primary"
              onClick={(e: any) => getTaxReport()}
              className="!bg-brand-500 hover:!bg-brand-600 active:!bg-brand-700 disabled:!bg-gray-200 flex items-center gap-2"
            >
              <MdFileDownload />
              Tax Report
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DownloadReportsModal;
