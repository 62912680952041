import { BatchCreateType, BatchIdType, PayslipItemTypeType, PayslipType } from "types/payroll-types";
import { ApiCore } from "./axios"
import * as url from "./urls";
import { AxiosResponse } from "axios";

import { LeaveDetailsType } from "pages/leaves/Leaves";
import { LeaveInfoType } from "features/leaves/RegisterLeaveTypeForm";
import { RequestLeaveType } from "features/leaves/RequestLeaveForm";
import { AssignMaxLeavesType } from "features/leaves/AssignMaxLeavesForm";
import { DependantsDataType, EmployeeCreateType } from "types/employee-types";
import { EmployeeUpdateType } from "features/employees/EmployeeBioDataForm";

// Careers Imports
import { JobOpeningType } from "types/careers-types";

const api = new ApiCore();

export interface loginUserType {
  username: string;
  password: string;
}
export type authCodeType = string

interface ContractData {
  employee: string;
  clauses_in_contract: number[];
}



// AUTHENTICATION API CALLS
export const loginAPI = async (data: loginUserType) => {
  return await api.post(url.LOGIN_URL, data);
}

export const loginGoogleAPI = async (authCode: string) => {
  const data={
    'authorization_code': authCode,
    'company_name': 'telerelation',
  } 
  return await api.post(url.GOOGLE_LOGIN_URL, data);
}

// EMPLOYEE API CALLS
export const fetchEmployeeOwnProfileAPI = async () => {
  return await api.get(url.EMPLOYEE_PROFILE_URL);
}
export const fetchSpecificEmployeeAPI = async (employeeId: string) => {
  return await api.get(`${url.EMPLOYEES_URL}${employeeId}`);
}

export const getAllEmployees = async () => {
  return await api.get(url.GET_ALL_EMPLOYEES, {})
}

export const fetchEmployeesWithIncomeAPI = async () => {
  return await api.get(url.EMPLOYEES_URL+'?total_income__isnull=false');
}

export const updateEmployeeAPI = async (employeeId: string, data: EmployeeUpdateType) => {
  return await api.update(`${url.EMPLOYEES_URL}${employeeId}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export const createEmployeeAPI = async (data: EmployeeCreateType) => {
  return await api.post(url.CREATE_EMPLOYEE_BY_USER, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export const updateEmployeeByUserAPI = async (data: EmployeeCreateType) => {
  return await api.update(url.UPDATE_EMPLOYEE_BY_USER, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export const getBanksAPI = async () => {
  return await api.get(url.GET_ALL_BANKS);
}

// DEPENDENTS API CALLS
export const createDependentsAPI = async (data: DependantsDataType[]) => {
  return await api.post(url.DEPENDENTS_BULK_CREATE, data);
}

// CONTRACTS API CALLS
export const getClausesAPI = async () => {
  return await api.get(url.GET_CLAUSES_URL)
}

export const createContractAPI = async (contractData: ContractData): Promise<AxiosResponse<any>> => {
  return api.post(url.CONTRACTS_URL, contractData);
};

export const getContractAPI = async (id: string) => {
  return await api.get(url.CONTRACTS_URL + id + "/")
}

export const getMyContractAPI = async () => {
  return await api.get(url.MY_CONTRACT_URL)
}

// LEAVES API CALLS
export const getEmployeeLeavesInfo = async (id: string) => {
  return await api.get(url.GET_EMPLOYEE_LEAVES_INFO + id + "/", {})
}

export const getEmployeeLeavesDetails = async (id: string) => {
  return await api.get(`${url.GET_EMPLOYEE_LEAVES_DETAILS}${id}/`, {})
}

export const getEmployeeLeavesInfoByUser = async () => {
  return await api.get(url.GET_EMPLOYEE_LEAVES_INFO, {})
}

export const getEmployeeLeavesDetailsByUser = async () => {
  return await api.get(url.GET_EMPLOYEE_LEAVES_DETAILS, {})
}

export const getPendingLeavesDetails = async () => {
  return await api.get(url.GET_PENDING_LEAVES_DETAILS, {})
}

export const getApprovedLeavesDetails = async () => {
  return await api.get(url.GET_APPROVED_LEAVES_DETAILS, {})
}

export const getDeclinedLeavesDetails = async () => {
  return await api.get(url.GET_DECLILNE_LEAVES_DETAILS, {})
}

export const getRemainingLeavesTypesInfo = async () => {
  return await api.get(url.REMAINING_LEAVES_TYPES_INFO, {})
}

export const getLeaveDetails = async (id: string) => {
  return await api.get(url.LEAVE_DETAILS + id + "/", {})
}

export const postApproveLeave = async (id: string, leaveData: LeaveDetailsType, comments: string) => {
  const data = {
    "start_date": leaveData.start_date,
    "end_date": leaveData.end_date,
    "reason": leaveData.reason,
    "comments": comments,
  };
  return await api.post(url.LEAVE_DETAILS + id + "/approve/", data);
}

export const postDeclineLeave = async (id: string, leaveData: LeaveDetailsType, comments: string) => {
  const data = {
    "start_date": leaveData.start_date,
    "end_date": leaveData.end_date,
    "reason": leaveData.reason,
    "comments": comments,
  };
  return await api.post(url.LEAVE_DETAILS + id + "/decline/", data);
}

export const postRequestLeave = async (leaveData: RequestLeaveType) => {
  const data = {
    "leave_info_id": leaveData.leave_info_id,
    "start_date": leaveData.start_date,
    "end_date": leaveData.end_date,
    "reason": leaveData.reason,
  };
  return await api.post(url.REQUEST_LEAVE, data);
}

export const getLeaveTypes = async () => {
  return await api.get(url.LEAVE_TYPE);
}

export const postLeaveType = async (leaveData: LeaveInfoType) => {
  const data = {
    "description": leaveData.description,
    "is_paid": leaveData.is_paid,
  };
  return await api.post(url.LEAVE_TYPE, data);
}

export const postLeaveInfo = async (leaveData: AssignMaxLeavesType) => {
  const data = {
    "employee": leaveData.employee,
    "max_leaves_allowed": leaveData.max_leaves_allowed,
    "leave_type": leaveData.leave_type,
    "period_start": leaveData.period_start,
    "period_end": leaveData.period_end,
  };
  return await api.post(url.LEAVE_INFO, data);
}

// PAYROLL API CALLS
export const fetchNonAutoPayslipLineItemTypesAPI = async () => {
  return await api.get(url.PAYSLIP_NON_AUTO_ITEM_TYPES_URL);
}

export const payslipCreateAPI = async (data: PayslipType) => {
  return await api.post(url.PAYSLIP_URL, data);
}

export const fetchAllPayslipsAPI = async (pageSize:number) => {
  return await api.get(url.PAYSLIP_URL + `?page_size=${pageSize}`);
}

export const fetchPayslipsPageAPI = async (pageUrl:string) => {
  return await api.get(pageUrl);
}

export const fetchSpecificPayslipAPI = async (payslipId: string) => {
  return await api.get(url.PAYSLIP_URL + `${payslipId}`);
}

export const fetchPayslipPdfUrlAPI = async (payslipId: PayslipType["id"]) => {
  return await api.get(url.PAYSLIP_PDF_URL + `${payslipId}`);
}

export const fetchSpecificBatchAPI = async (batchId: BatchIdType) => {
  return await api.get(url.PAYSLIP_BATCH_URL + `${batchId}`);
}

export const fetchAllBatchesAPI = async () => {
  return await api.get(url.PAYSLIP_BATCH_URL);
}

export const fetchEmployeeSpecificPayslipsAPI = async (employeeId: string) => {
  return await api.get(url.PAYSLIPS_EMPLOYEE_URL + `${employeeId}`);
}

export const payslipBatchCreateAPI = async (data: BatchCreateType) => {
  return await api.post(url.PAYSLIP_BATCH_URL, data);
}
export const fetchPayslipLineItemTypesAPI = async () => {
  return await api.get(url.PAYSLIP_LINE_ITEM_TYPES_URL);
}
export const payslipLineItemTypeCreateAPI = async (data: PayslipItemTypeType) => {
  return await api.post(url.PAYSLIP_LINE_ITEM_TYPES_URL, data);
}
export const fetchMyPayslipsAPI = async () => {
  return await api.get(url.MY_PAYSLIPS_URL);
}
export const fetchPayslipBlobAPI = async (payslipId: string) => {
  return await api.getBlob(url.PAYSLIP_PDF_URL+ `${payslipId}`);
}

export const fetchPayrollReportsAPI = async (
  startDate: Date | null,
  endDate: Date | null
) => {
  const formatDate = (date: Date | null) => {
    if (!date) return undefined;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const params = {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };

  return await api.get(url.PAYROLL_REPORTS_URL, {
    responseType: "blob",
    params: params,
  });
};

export const fetchPayrollTaxReportAPI = async (
  startDate: Date | null,
  endDate: Date | null
) => {
  const formatDate = (date: Date | null) => {
    if (!date) return undefined;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const params = {
    start_date: formatDate(startDate),
    end_date: formatDate(endDate),
  };

  return await api.get(url.PAYROLL_TAX_REPORT_URL, {
    responseType: "blob",
    params: params,
  });
};

// Appraisals API CALLS

export const getAssignedFormsForUser = async () => {
  return await api.get(url.GET_ASSIGNED_FORMS_FOR_USER);
}

export const getCompletedFormsForUser = async () => {
  return await api.get(url.GET_COMPLETED_FORMS_FOR_USER);
}

export const getFullAppraisalForm = async (id: string) => {
  return await api.get(`${url.GET_APPRAISAL_FORM}${id}/full-form/`);
}

export const postAppraisalForm = async (data: any) => {
  return await api.post(url.POST_APPRAISAL_FORM, data);
}

// Careers API Helpers
// export const getAllJobOpenings = async () => {
//   return await api.get(url.GET_ALL_JOB_OPENINGS);
// }
export const getAllJobOpenings = async () => {
  return await api.get(url.GET_ALL_JOB_OPENINGS);
}

// export const createJobOpening = async (data: JobOpeningType) => {
//   return await api.post(url.GET_ALL_JOB_OPENINGS, data);
// }
export const createJobOpening = async (data: any) => {
  return await api.post(url.GET_ALL_JOB_OPENINGS, data);
}
export const getJobDetails = async (slug: string) => {
  return await api.get(`${url.GET_ALL_JOB_OPENINGS}${slug}/`);
}

// export const getJobDetails = async (jobId: string) => {
//   return await api.get(`${url.GET_ALL_JOB_OPENINGS}${jobId}/`);
// }

// export const editJobDetails = async (data: JobOpeningType, jobId: string) => {
//   return await api.update(`${url.GET_ALL_JOB_OPENINGS}${jobId}/`, data);
// }
export const editJobDetails = async (data: JobOpeningType, slug: string) => {
  return await api.update(`${url.GET_ALL_JOB_OPENINGS}${slug}/`, data);
}

export const getAllApplicants = async () => {
  return await api.get(url.GET_ALL_APPLICANTS);
}

export const getAllJobApplications = async () => {
  return await api.get(url.GET_ALL_JOB_APPLICATIONS);
}

export const getJobApplicationDetails = async (applicationId: string) => {
  return await api.get(`${url.GET_ALL_JOB_APPLICATIONS}${applicationId}/`);
}

export const updateJobApplicationDetails = async (applicationId: string, data: any) => {
  return await api.update(`${url.GET_ALL_JOB_APPLICATIONS}${applicationId}/update-note/`, data);
}

export const getApplicantDetails = async (applicantId: string) => {
  return await api.get(`${url.GET_ALL_APPLICANTS}${applicantId}/`);
}

export const updateApplicantDetails = async (applicantId: string, data: any) => {
  return await api.update(`${url.GET_ALL_APPLICANTS}${applicantId}/update-note/`, data);
}

export const getAllJobApplicationsStatus = async () => {
  return await api.get(url.GET_STATUS_CHOICES);
}

export const updateJobApplicationStatus = async (applicationId: string, status: string) => {
  return await api.update(`${url.GET_ALL_JOB_APPLICATIONS}${applicationId}/update-status/`, {status});
}

export const getAllSkills = async () => {
  return await api.get(url.GET_SKILLS);
}

export const postSkill = async (data: any) => {
  return await api.post(url.GET_SKILLS, data);
}

export const getDepartments = async () => {
  return await api.get(url.DEPARTMENTS_URL);
}
