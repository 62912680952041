import { useState, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import InputField from "components/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/Checkbox";
import Backdrop from "components/Backdrop";
import config from "config";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { LoginType, loginUser } from "app/slices/Auth/thunk";
import ErrorBox from "components/ErrorBox";
import trImage from "assets/img/auth/tr-logo-with-text.png";

const SignIn = () => {
  const dispatch = useAppDispatch();

  const { loginError, isLoading } = useAppSelector(state=>({
    accessToken : state.auth.accessToken,
    permissions: state.auth.permissions,
    loginError: state.auth.loginError,
    isLoading: state.auth.loading
  }))

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
 

  const GOOGLE_CLIENT_ID:string = config.google.CLIENT_ID;
  const GOOGLE_REDIRECT_URL:string = config.google.REDIRECT_URL;
  const navigate: NavigateFunction = useNavigate();
  
  const handleGoogleLoginClick = () => {
    const clientID:string = GOOGLE_CLIENT_ID;
    const redirectURI:string = GOOGLE_REDIRECT_URL;
    const authURL:string = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${clientID}&redirect_uri=${redirectURI}&scope=email+profile`;

    window.location.href = authURL;
  };

  const getQueryParams = (url:string) => {
    const queryParams: URLSearchParams = new URLSearchParams(url);
    const code:string = queryParams.get("code");
    return code;
  };

  useEffect(() => {
    const code:string = getQueryParams(window.location.search);
    if (code) {
      dispatch(loginUser(code, ()=>{navigate('/')}, LoginType.Google))
    }
  },[navigate, dispatch])

  const handleLogin = async (event:React.FormEvent) => {
    event.preventDefault();
    dispatch(
      loginUser(
        {
          username: username,
          password: password,
        },
        () => {navigate('/')},
      ));
  };

  return (
    
    isLoading ? 
      <Backdrop show={isLoading}/>
    :
    <>
    <div className="mt-8 mb-8 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <ErrorBox errorMessage={loginError} />
        <div onClick={handleGoogleLoginClick} className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-900">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Telerelation Account
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div>
        <p className="mb-5 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <form onSubmit={handleLogin}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Username*"
            placeholder="Username"
            id="email"
            type="text"
            value={username}
            required
            onChange={(e) => setUsername(e.target.value)} 
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href=" "
            >
              Forgot Password?
            </a>
          </div>
          <button type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Sign In
          </button>
        </form>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div>
      </div>
    </div>
    <div className="absolute right-0 hidden h-full min-h-screen lg:flex items-center p-12 lg:w-[50%] 2xl:w-[44vw] bg-lightPrimary dark:bg-navy-900">
      <img src={trImage} alt="telerelation" className="w-full" />
    </div>
    </>
  );
};

export default SignIn;
