import { Link } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { PayslipListResponse, PayslipType } from "types/payroll-types";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import ManualPaginationTable from "components/ManualPaginationTable";
import { formatAmount } from "utils/utilityFunctions";

interface PayslipsTableType {
  tableData: PayslipListResponse,
  tableName: string,
  ownPayslips?: boolean,
  pageLimit: number,
  currentPageNumber: number;
  getPayslipPage: (pageUrl: string, action: string) => void;
  getPayslipPageWithLimit: (pageSize: number) => void;
}

const PayslipsPaginatedTable = (props: PayslipsTableType) => {
  const { tableData, tableName, ownPayslips, pageLimit, currentPageNumber, getPayslipPage, getPayslipPageWithLimit } = props;
  const payslips_list = tableData.results;
  const columnHelper = createColumnHelper<PayslipType>();
  const columns:ColumnDef<PayslipType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info) => (
        <Link to={ownPayslips? `/my-payslip/${info.row.original.id}`:`/payslip/${info.row.original.id}`} className="text-sm underline font-bold text-blue-700 dark:text-white">
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("employee_details.first_name", {
      id: "first_name",
      header: () => (
        <TableHeader>First Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("employee_details.last_name", {
      id: "last_name",
      header: () => (
        <TableHeader>Last Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <TableHeader>Status</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("net_salary", {
      id: "net_salary",
      header: () => (
        <TableHeader>Net Salary</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {formatAmount(info.getValue())}
        </TableCell>
      ),
    }),
    columnHelper.accessor("period_start", {
      id: "period_start",
      header: () => (
        <TableHeader>Period Start</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("period_end", {
      id: "period_end",
      header: () => (
        <TableHeader>Period End</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
  ];
  
  return (
    <ManualPaginationTable<PayslipType> tableData={payslips_list} columns={columns} tableHeading={tableName} totalRowCount={tableData.count}
        pageCount={pageLimit > 0? Math.ceil(tableData.count/pageLimit):1} pageRowsCount={pageLimit} previousUrl={tableData.previous} nextUrl={tableData.next}
        getPayslipPage={getPayslipPage} getPayslipPageWithLimit={getPayslipPageWithLimit} currentPageNumber={currentPageNumber}
    />
  )
}

export default PayslipsPaginatedTable;
