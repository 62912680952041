import React from "react";
import { Link } from "react-router-dom";
import { userRoutes } from "routes";

export interface BreadCrumbDataType {
  routePath: string;
  routeLink: string;
  routeName: string;
}

interface BreadCrumbsPropType {
  bread_crumbs: BreadCrumbDataType[];
}

const BreadCrumbs = (props: BreadCrumbsPropType) => {
  return (
    <div className="flex items-center space-x-1">
      {props.bread_crumbs.map((crumb, index) => (
        <React.Fragment key={crumb.routePath}>
          {index > 0 && <span className="text-gray-500 px-2">{' > '}</span>}
          {index < props.bread_crumbs.length - 1 && userRoutes.find(route => route.path === crumb.routePath)? (
            <Link
              to={crumb.routeLink}
              className="text-base text-blue-800 font-bold hover:text-blue-800 capitalize"
            >
              {crumb.routeName}
            </Link>
          ) : (
            <span className="text-gray-600 capitalize">
              {crumb.routeName}
            </span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default BreadCrumbs;
