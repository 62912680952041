import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { Link } from "react-router-dom";
import Table from "components/Table";
import { BsPersonVcardFill } from "react-icons/bs";
import { formatAmount } from "utils/utilityFunctions";

interface AllEmployeesTablePropsType {
  tableData: EmployeeType[] | [];
}

const EmployeesListTable = (props: AllEmployeesTablePropsType) => {
  const { tableData } = props;

  const columnHelper = createColumnHelper<EmployeeType>();
  const columns: ColumnDef<EmployeeType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info) => (
        <Link to={`/employee/${info.getValue()}`} className="flex align-middle text-sm underline font-bold text-blue-700 dark:text-white">
            {info.getValue()}
            <BsPersonVcardFill size={18} className="mx-2"/>
        </Link>

      ),
    }),
    columnHelper.accessor("first_name", {
      id: "first_name",
      header: () => (
        <TableHeader>First Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("last_name", {
      id: "last_name",
      header: () => (
        <TableHeader>Last Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("designation", {
      id: "designation",
      header: () => (
        <TableHeader>Designation</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("phone", {
      id: "phone",
      header: () => (
        <TableHeader>Phone</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("total_income", {
      id: "total_income",
      header: () => (
        <TableHeader>Total Wage</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {formatAmount(info.getValue())}
        </TableCell>
      ),
    }),

  ];

  return (
    <Table<EmployeeType> tableData={tableData} columns={columns} tableHeading="All Employees" />
  );
}

export default EmployeesListTable;
