
import { useEffect, useState } from "react";
import PayslipBatchDataTable from "features/payslips/PayslipBatchDataTable";
import { useNavigate, useParams } from "react-router-dom";
import { PayslipType, BatchIdType, BatchTablePayslipType } from "types/payroll-types";
import { AxiosResponse } from "axios";
import { fetchSpecificBatchAPI } from "api/apiHelper";
import Backdrop from "components/Backdrop";

interface PayslipBatchType {
    id: number;
    period_start: string;
    period_end: string;
    payslips: PayslipType[];
}

const PayslipBatch = () => {
    const [paySlipBatchData, setPaySlipBatchData] = useState<PayslipBatchType | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const [payslipsTableData, setPayslipsTableData] = useState<BatchTablePayslipType[]>([]);
    const { batchId } = useParams<BatchIdType>();
    const navigate = useNavigate();
    async function getPaySlipBatch() {
        try {
            const response:AxiosResponse<PayslipBatchType> = await fetchSpecificBatchAPI(batchId);
            console.log("Batch Data: ",response.data);
            setPaySlipBatchData(response.data);
            setIsLoading(false);
            if (response.data.payslips) { 
                let payslips: BatchTablePayslipType[] = []
                response.data.payslips.forEach((payslip: PayslipType) => {
                
                    const singlePayslipData:BatchTablePayslipType = {
                        name : payslip.employee_details?.first_name + " " + payslip.employee_details?.last_name,
                        total_income : payslip.employee_details?.total_income,
                        net_salary : payslip.net_salary,
                        id: payslip.id
                    }
                    payslips.push(singlePayslipData);
                })
                setPayslipsTableData(payslips);
            }
        } catch (error: any) {
            console.log(error);
            setIsLoading(false);
            setError(error.message);
        }
    }
    useEffect( () => {
        if(!batchId.match(/\d+/)) {
            navigate('/not-found');
            return;
        }

        getPaySlipBatch();
    }, [batchId]);

    return (
        <div className="container mt-5">
            { isLoading ? (
                <Backdrop show={isLoading} />
            ) : error ? (
                <p className="text-red">{error}</p>
            ) : paySlipBatchData ?(
                <div>
                    <div className="mb-5 text-center text-navy-700 dark:text-white">
                        <h1 className="text-5xl font-bold">{paySlipBatchData.id}</h1>
                        <div className="border-t-2"></div>
                        <div className="flex flex-col mt-4">
                            <span className="font-bold text-lg mb-1">Period Start:</span>
                            <span className="text-gray-700">{paySlipBatchData.period_start}</span>
                        </div>
                        <div className="flex flex-col mt-2">
                            <span className="font-bold text-lg mb-1">Period End:</span>
                            <span className="text-gray-700">{paySlipBatchData.period_end}</span>
                        </div>
                    </div>
                    {
                        payslipsTableData && <PayslipBatchDataTable tableData={payslipsTableData} tableName={"Batch Payslips "} batchId={paySlipBatchData.id} />
                    }
                </div>
            ) : (
                <div></div>
            )
            }

        </div>
    )


}

export default PayslipBatch;
