import React from "react";
import { JobApplicantType } from "types/careers-types";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { Link } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import Table from "components/Table";

interface ApplicantsTableProps {
  jobApplicants: JobApplicantType[] | [];
}

const ApplicantsTable = (props: ApplicantsTableProps) => {
  const { jobApplicants } = props;
  const columnHelper = createColumnHelper<JobApplicantType>();
  const columns: ColumnDef<JobApplicantType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => <TableHeader>ID</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => <TableHeader>NAME</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => <TableHeader>EMAIL</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("contact", {
      id: "contact",
      header: () => <TableHeader>CONTACT</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("city", {
      id: "city",
      header: () => <TableHeader>CITY</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => <TableHeader>VIEW</TableHeader>,
      cell: (info) => (
        <Link to={`/careers/applicant/${info.row.original.id}`}>
          <TableCell className="!w-max !min-w-0 !pr-0">
            <MdVisibility className="h-6 w-6 text-gray-600" />
          </TableCell>
        </Link>
      ),
    }),
  ];

  return (
    <Table<JobApplicantType>
      tableData={jobApplicants}
      columns={columns}
      tableHeading="Job Applicants"
    />
  );
};

export default ApplicantsTable;
