import { useState, useEffect } from "react";
import LeavesInfo from "features/leaves/LeavesInfo";
import { getEmployeeLeavesInfoByUser, getEmployeeLeavesDetailsByUser } from "api/apiHelper";
import { EmployeeLeaveInfoType, EmployeeLeavesDetailsType } from "types/leaves-types";
import TabsContainer from "components/TabsContainer";
import Tab from "components/Tab"; 
import RequestLeaveForm from "features/leaves/RequestLeaveForm";
import LeavesStatusTable from "features/leaves/LeavesStatusTable";
import { usePermissions } from "app/hooks";

const LeavesDetails = () => {
  const [leaveInfo, setLeaveInfo] = useState<EmployeeLeaveInfoType[] | []>([]);
  const [leavesDetail, setLeavesDetail] = useState<EmployeeLeavesDetailsType[] | []>([]);
  const permissions = usePermissions() || [];

  const fetchLeaves = async () => {
    if (permissions && permissions.includes('leaves.view_my_employeeleaveinfo')){
      try {
        const response = await getEmployeeLeavesInfoByUser();
        setLeaveInfo(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (permissions && permissions.includes('leaves.view_my_employeeleavesdetail')) {
      try {
        const response = await getEmployeeLeavesDetailsByUser();
        setLeavesDetail(response.data);
      } catch (error) {
        console.log(error);
        console.log('Amnaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
      }
    }
  }

  useEffect(() => {
    fetchLeaves();
  }, []);

  return (
    <div className="mt-5">
      <TabsContainer>
        <Tab isActive={true} title="Leaves Info">
          <div className="flex flex-col gap-4">
            { permissions && permissions.includes('leaves.view_my_employeeleaveinfo') &&
              <LeavesInfo leaveData={leaveInfo} />
            }
            { permissions && permissions.includes('leaves.view_my_employeeleavesdetail') && 
              <LeavesStatusTable tableData={leavesDetail} tableHeading="Leaves Details"  />
            }
          </div>
        </Tab>
        { permissions && permissions.includes('leaves.add_my_employeeleavesdetail') && permissions.includes('leaves.view_my_employeeleaveinfo') &&
          <Tab isActive={false} title="Request Leave">
            <RequestLeaveForm />
          </Tab>
        }
      </TabsContainer>
    </div>
  );
};

export default LeavesDetails;
