import React from "react";
import { Link } from "react-router-dom";

interface ButtonPropsType {
  url?: string;
  name: string;
  icon: string;
  extraStyles?: string;
  onClick?: () => void;
}

const IconButton = (props: ButtonPropsType) => {
  return (
    <div onClick={props.onClick} className="inline-flex">
      <Link to={props.url} className="no-underline">
        <button
          className={`bg-brand-500 flex gap-2 justify-between items-center !leading-3 uppercase hover:bg-brand-600 dark:bg-brand-400 dark:hover:bg-brand-500 rounded-lg p-3 text-[0.8rem] font-bold text-white ${props.extraStyles}`}
        >
          {props.name}
          <div className="flex w-5 items-center justify-center">
            <i className={props.icon} aria-hidden="true"></i>
          </div>
        </button>
      </Link>
    </div>
  );
};

export default IconButton;