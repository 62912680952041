import Card from "components/Card";
import { LeaveDetailsType } from "pages/leaves/Leaves";

interface LeaveInfoPropsType {
  leaveDetails: LeaveDetailsType | null; 
}

const LeaveInfo:React.FC<LeaveInfoPropsType> = ({ leaveDetails }) => {
  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Leave Information
        </h4>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Employee ID
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.employee_id || 0}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            First Name
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.employee_first_name || ""}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Last Name
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.employee_last_name || ""}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Leave Type
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.leave_type_description || ""}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Status
          </p>
          {leaveDetails?.leave_status === "P" ? (
              <p className="text-base font-medium px-2 py-1 w-max rounded-xl text-gray-700 bg-gray-200 dark:!text-gray-200 dark:bg-gray-700">
                Pending
              </p>
            ) : leaveDetails?.leave_status === "A" ? (
              <p className="text-base font-medium px-2 py-1 w-max rounded-xl text-green-700 bg-green-200 dark:!text-green-200 dark:bg-green-700">
                Approved
              </p>
            ) : leaveDetails?.leave_status === "D" ? (
              <p className="text-base font-medium px-2 py-1 w-max rounded-xl text-red-700 bg-red-200 dark:!text-red-200 dark:bg-red-700">
                Declined
              </p>
            ) : (
              <p className="text-base font-medium px-2 py-1 w-max rounded-xl">
                {leaveDetails?.leave_status || "N/A"}
              </p>
            )
          }
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Start Date
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.start_date || ""}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            End Date
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.end_date || ""}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Reason
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.reason || ""}
          </p>
        </div>
        <div className="flex w-full flex-col justify-center p-4 rounded-2xl bg-white bg-clip-border py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">
            Comments
          </p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {leaveDetails?.comments || "N/A"}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default LeaveInfo;
