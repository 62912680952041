import { Outlet } from "react-router-dom";
import FixedPlugin from "components/DarkModeToggleButton";
const AuthLayout = () => {
  return (
    <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-800">
      <div className="fixed top-[35px] right-[35px] !z-[99]">
        <FixedPlugin className="bg-brand-500 w-12 h-12 rounded-full" />
      </div>
      <main className="mb-auto flex flex-col p-5 lg:max-w-[50%] min-h-full w-full justify-start pt-12 md:max-w-[75%] mx-auto lg:mx-0 lg:h-screen lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
        <Outlet />
        
      </main>
    </div>
  );
};

export default AuthLayout;
