import { useState, useEffect } from "react";
import Card from "components/Card";
import InputField from "components/InputField";
import FileUploadField from "components/FileInputField";
import SelectField from "components/SelectField";
import Button from "components/Button";
import { toast } from "react-toastify";
import { fetchEmployeeOwnProfileAPI, updateEmployeeByUserAPI, getBanksAPI } from "api/apiHelper";
import Backdrop from "components/Backdrop";
import ErrorBox from "components/ErrorBox";
import { DependantsDataType, EmployeeUpdateType } from "types/employee-types";
import { usePermissions } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { BankType } from "types/employee-types";


const EditEmployeeBioDataForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const permissions = usePermissions() || [];
  const [profileData, setProfileData] = useState<EmployeeType | null>(
    null
  );
  const [banks, setBanks] = useState<BankType[]>([]);
  const navigate = useNavigate();

  const fetchProfile = async () => {
    setIsLoading(true);
    try {
      const response = await fetchEmployeeOwnProfileAPI();
      const photo = await fetch(response.data.photo);
      const photoBlob = await photo.blob();
      const photoFile = new File([photoBlob], "profile.jpg", { type: "image/jpeg" });
      response.data.photo = photoFile;
      setProfileData(response.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBanks = async () => {
    try {
      const response = await getBanksAPI();
      console.log("Banks: ", response.data);
      setBanks(response.data);
    } catch (error: any) {
      setError(error.message);
    }
  }

  useEffect(() => {
    fetchBanks();
    fetchProfile();
  }, []);

  const [formData, setFormData] = useState<EmployeeUpdateType>({
    employee: {
      id: "",
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      CNIC: "",
      phone: "",
      address: "",
      photo: "",
      total_income: 0,
      designation: "",
      company: "",
      user: null,
      date_joined: null,
      bank_detail: {
        id: "",
        bank: "",
        account_title: "",
        account_number: "",
      },
    },
    dependents: [],
  });

  const initializeFormData = (data: EmployeeType | null) => {
    if (data) {
      setFormData({
        employee: {
          id: data.id || "",
          first_name: data.first_name || "",
          last_name: data.last_name || "",
          date_of_birth: data.date_of_birth || "",
          gender: data.gender || "",
          CNIC: data.CNIC || "",
          phone: data.phone || "",
          address: data.address || "",
          photo: data.photo || "",
          total_income: data.total_income || 0,
          designation: data.designation || "",
          company: data.company || "",
          user: data.user,
          date_joined: data.date_joined,
          bank_detail: data.bank_detail || {
            bank: "",
            account_title: "",
            account_number: "",
          },
        },
        dependents: data.dependents,
      });
    }
  };

  useEffect(() => {
    initializeFormData(profileData);
  }, [profileData]);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form Data: ", formData);
    setIsLoading(true);
    try {
      await updateEmployeeByUserAPI(formData);
      toast.success("Profile updated successfully.");
      navigate("/profile");
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormReset = () => {
    initializeFormData(profileData);
  };

  const handleFileChange = (file: File | null) => {
    if (file) {
      setFormData({
        ...formData,
        employee: {
          ...formData.employee,
          photo: file,
        },
      });
    }
  };

  const handleFileCancel = () => {
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        photo: null,
      },
    });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        [id]: value ? value : id === "total_income" ? 0 : "",
      },
    });
  };

  const handleBankDetailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      employee: {
        ...formData.employee,
        bank_detail: {
          ...formData.employee.bank_detail,
          [id]: value,
        },
      }
    });
  };

  const handleDependentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { id, value } = e.target;
    const newDependents = formData.dependents.map((dependent, i) => {
      if (i === index) {
        return {
          ...dependent,
          [id]: value,
        };
      }
      return dependent;
    });

    setFormData({
      ...formData,
      dependents: newDependents,
    });
  };

  const handleAddDependent = () => {
    const newDependent: DependantsDataType = {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "M",
      relationship: "Spouse",
    };

    setFormData({
      ...formData,
      dependents: [...formData.dependents, newDependent],
    });
  };

  const handleRemoveDependent = (index: number) => {
    const newDependents = formData.dependents.filter(
      (_, i) => i !== index
    );

    setFormData({
      ...formData,
      dependents: newDependents,
    });
  };

  return isLoading ? (
    <Backdrop show={isLoading} />
  ) : permissions && permissions.includes("employees.view_my_employee") ? (
    <Card extra={"w-full h-full pb-10 p-4"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Bio Data Form
        </h4>
      </div>
      {error && <ErrorBox errorMessage={error} />}
      {/* Form */}
      <form
        className="flex flex-col gap-4"
        onSubmit={handleFormSubmit}
        onReset={handleFormReset}
      >
        <FileUploadField
          id="photo"
          label="Profile photo"
          acceptedFileTypes={[
            "image/jpeg",
            "image/png",
            "image/jpg",
            "image/svg",
          ]}
          onFileChange={handleFileChange}
          onCancel={handleFileCancel}
          value={formData.employee.photo}
          required={!formData.employee.photo}
        />
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="first_name"
            label="First Name"
            type="text"
            placeholder="Enter first name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.first_name}
            required
          />
          <InputField
            id="last_name"
            label="Last Name"
            type="text"
            placeholder="Enter last name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.last_name}
            required
          />
          <InputField
            id="company"
            label="Company"
            type="text"
            placeholder="Enter company name"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.company}
            required
            disabled
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="total_income"
            label="Total Income"
            type="number"
            placeholder="Enter total income"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.total_income}
            required
            disabled
          />
          <InputField
            id="phone"
            label="Phone"
            type="text"
            placeholder="Enter phone"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.phone}
            required
          />
          <InputField
            id="designation"
            label="Designation"
            type="text"
            placeholder="Enter designation"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.designation}
            required
            disabled
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <InputField
            id="date_of_birth"
            label="Date of Birth"
            type="date"
            placeholder="Enter date of birth"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.date_of_birth}
            required
          />
          <SelectField
            id="gender"
            label="Choose a gender"
            options={[
              { label: "Select", value: "" },
              { label: "Male", value: "M" },
              { label: "Female", value: "F" },
              { label: "Other", value: "O" },
            ]}
            onChange={handleChange}
            value={formData.employee.gender}
            required
          />
          <InputField
            id="CNIC"
            label="CNIC"
            type="text"
            placeholder="Enter CNIC"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.CNIC}
            required
          />
        </div>
        <div className="grid w-full grid-cols-1 gap-4">
          <InputField
            id="address"
            label="Address"
            type="text"
            placeholder="Enter address"
            extra="w-full"
            variant="outlined"
            onChange={handleChange}
            value={formData.employee.address}
            required
          />
        </div>
        <div className="mb-8 mt-2 flex w-full items-center justify-between border-t pt-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Bank Details
          </h4>
        </div>
        <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
          <SelectField
            id="bank"
            label="Bank"
            options={[
              { label: "Select Bank", value: "" },
              ...banks.map((bank) => ({ label: bank.name, value: bank.id })),
            ]}
            onChange={handleBankDetailChange}
            value={formData.employee.bank_detail.bank}
            required
          />
          <InputField
            id="account_title"
            label="Account Title"
            type="text"
            placeholder="Enter account title"
            extra="w-full"
            variant="outlined"
            onChange={handleBankDetailChange}
            value={formData.employee.bank_detail.account_title}
            required
          />
          <InputField
            id="account_number"
            label="Account Number"
            type="text"
            placeholder="Enter account number"
            extra="w-full"
            variant="outlined"
            onChange={handleBankDetailChange}
            value={formData.employee.bank_detail.account_number}
            required
          />
        </div>
        <div className="mb-8 mt-2 flex w-full items-center justify-between border-t pt-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Dependants Details
          </h4>
          <Button
            type="button"
            varient="primary"
            className="rounded px-4 py-2"
            onClick={handleAddDependent}
          >
            Add Dependent
          </Button>
        </div>

        {formData.dependents.map((dependent, index) => (
          <div className="mb-8 mt-2 flex w-full flex-col gap-4" key={index}>
            <div className="flex justify-between items-center">
              <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                Dependant {index + 1}
              </h4>
              <Button
                type="button"
                varient="danger"
                className="rounded px-4 py-2"
                onClick={() => handleRemoveDependent(index)}
              >
                Remove Dependent
              </Button>
            </div>
            <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
              <InputField
                id="first_name"
                label="First Name"
                type="text"
                placeholder="Enter first name"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].first_name}
                required
              />
              <InputField
                id="last_name"
                label="Last Name"
                type="text"
                placeholder="Enter last name"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].last_name}
                required
              />
            </div>
            <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-3">
              <InputField
                id="date_of_birth"
                label="Date of Birth"
                type="date"
                placeholder="Enter date of birth"
                extra="w-full"
                variant="outlined"
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].date_of_birth}
                required
              />
              <SelectField
                id="gender"
                label="Choose a gender"
                options={[
                  { label: "Select", value: "" },
                  { label: "Male", value: "M" },
                  { label: "Female", value: "F" },
                  { label: "Other", value: "O" },
                ]}
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].gender}
                required
              />
              <SelectField
                id="relationship"
                label="Relationship"
                options={[
                  { label: "Select", value: "" },
                  { label: "Spouse", value: "S" },
                  { label: "Child", value: "C" },
                  { label: "Parent", value: "P" },
                  { label: "Other", value: "O" },
                ]}
                onChange={(e) => handleDependentChange(e, index)}
                value={formData.dependents[index].relationship}
                required
              />
            </div>
          </div>
        ))}
        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">
            Cancel
          </Button>
          <Button type="submit" varient="primary" className="w-max">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  ) : (
    <ErrorBox errorMessage="Insufficient privileges." />
  );
};

export default EditEmployeeBioDataForm;
