import Switch from "./Switch"

const SwitchField = (props: {
  id: string;
  label: string;
  desc: string;
  checked: boolean;
  placeholder: string;
  mt: any;
  mb: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { id, label, desc, checked, mt, mb } = props;
  return (
    <div className={`flex justify-between ${mt} ${mb} items-center`}>
      <label
        htmlFor={id}
        className="max-w-[80%] hover:cursor-pointer lg:max-w-[65%]"
      >
        <h5 className="text-base font-bold text-navy-700 dark:text-white">
          {label}
        </h5>
        {/* <p className={`text-base text-gray-600`}>{desc}</p> */}
      </label>
      <div>
        <Switch id={id} {...props} />
      </div>
    </div>
  );
};

export default SwitchField;
