import { useState } from "react";
import Card from "components/Card";
import InputField from "components/InputField";
import Button from "components/Button";
import { postApproveLeave, postDeclineLeave } from "api/apiHelper";
import { LeaveDetailsType } from "pages/leaves/Leaves";
import { toast } from "react-toastify";

interface ApproveLeaveFormPropsType {
  leaveId: string;
  leaveDetails: LeaveDetailsType | null; 
  updateLeaveDetails?: (leaveId: string) => void;
}

const ApproveLeaveForm = (props: ApproveLeaveFormPropsType) => {

  const { leaveId, leaveDetails, updateLeaveDetails } = props;
  const [comments, setComments] = useState("");
  const handleApprove = async () => {
    const notification = toast.loading("Approving Leave...");

    try {
      const response = await postApproveLeave(leaveId, leaveDetails, comments);
      console.log("leave approve response: ", response);
      toast.update(notification, {
        render: "Leave Approved successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      updateLeaveDetails(leaveId);
    } catch (error) {
      console.log(error);
      toast.update(notification, {
        render: "Failed to approve Leave",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }

  const handleDecline = async () => {
    const notification = toast.loading("Declining Leave...");

    try {
      const response = await postDeclineLeave(leaveId, leaveDetails, comments);
      console.log("leave decline response: ", response);
      toast.update(notification, {
        render: "Leave Declined successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      updateLeaveDetails(leaveId);
    } catch (error) {
      console.log(error);
      toast.update(notification, {
        render: "Failed to decline Leave",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }

  const handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComments(e.target.value);
  }

  return (
    <Card extra={"w-full h-full pb-10 p-4"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Approve Leave
        </h4>
      </div>

      {/* Form */}
      <div>
        <form className="flex gap-2 w-full">
          <InputField
            id="comments"
            type="text"
            placeholder="Enter comments here..."
            extra="w-full"
            variant="outlined"
            onChange={handleCommentsChange}
            value={comments}
            required
          />
          
          <Button type="button" varient="danger" onClick={handleDecline}>Decline</Button>
          <Button type="button" varient="success" onClick={handleApprove}>Approve</Button>
        </form>
      </div>
    </Card>
  );
};

export default ApproveLeaveForm;
