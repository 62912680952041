import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import Table from "components/Table";
import { PayslipItemTypeType } from "types/payroll-types";
import { formatAmount } from "utils/utilityFunctions";

interface PayslipLineItemTypesTableType {
  tableData: PayslipItemTypeType[] | [];
}

const PayslipLineItemTypesTable = (props: PayslipLineItemTypesTableType) => {
  const { tableData } = props;

  const columnHelper = createColumnHelper<PayslipItemTypeType>();
  const columns: ColumnDef<PayslipItemTypeType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: () => (
        <TableHeader>Description</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    
    columnHelper.accessor("is_positive", {
      id: "is_positive",
      header: () => (
        <TableHeader>Effect</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    
    columnHelper.accessor("order", {
      id: "order",
      header: () => (
        <TableHeader>Order</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("percentage", {
      id: "percentage",
      header: () => (
        <TableHeader>Percentage</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()} %
        </TableCell>
      ),
    }),
    
    
    columnHelper.accessor("taxable", {
      id: "taxable",
      header: () => (
        <TableHeader>Taxable</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()? "Yes": "No"}
        </TableCell>
      ),
    }),
    columnHelper.accessor("source_auto", {
      id: "source_auto",
      header: () => (
        <TableHeader>Source Auto</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()? "Yes": "No"}
        </TableCell>
      ),
    }),
    columnHelper.accessor("special", {
      id: "special",
      header: () => (
        <TableHeader>Special</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("fixed_amount", {
      id: "fixed_amount",
      header: () => (
        <TableHeader>Fixed Amount</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {formatAmount(info.getValue())}
        </TableCell>
      ),
    }),
    columnHelper.accessor("of_item", {
      id: "of_item",
      header: () => (
        <TableHeader>Of Item</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    
  ];

  return (
    <Table<PayslipItemTypeType> tableData={tableData} columns={columns} tableHeading="Payslip Line Item Types" />
  );
}

export default PayslipLineItemTypesTable;
