import React, { useState, useEffect } from 'react';
import { JobOpeningType } from "types/careers-types";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { Link } from "react-router-dom";
import { MdVisibility } from 'react-icons/md';
import Table from "components/Table";
import SelectField from "components/SelectField";

interface JobsTableProps {
  jobs: JobOpeningType[] | [];
}

const ACTIVE_STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const JobsTable = (props: JobsTableProps) => {
  const { jobs } = props;
  const [selectedStatus, setSelectedStatus] = useState<string>('active');
  const [selectedDepartment, setSelectedDepartment] = useState<string>('all');
  const [departmentOptions, setDepartmentOptions] = useState<{ label: string; value: string }[]>([]);
  const [filteredJobs, setFilteredJobs] = useState<JobOpeningType[]>(jobs);

 
  useEffect(() => {
    const departments = jobs
      .map(job => job.department)
      .filter(dept => dept && dept.name)
      .map(dept => ({ label: dept.name, value: dept.id.toString() }));

    const uniqueDepartments = Array.from(new Set(departments.map(dept => dept.value)))
      .map(value => departments.find(dept => dept.value === value))
      .filter(Boolean) as { label: string; value: string }[];

    setDepartmentOptions([{ label: 'All Departments', value: 'all' }, ...uniqueDepartments]);
  }, [jobs]);

  
  useEffect(() => {
    const isActive = selectedStatus === 'active';
    let data = jobs.filter(job => job.is_active === isActive);

    if (selectedDepartment !== 'all') {
      const deptId = parseInt(selectedDepartment, 10);
      data = data.filter(job => job.department.id.toString() === deptId.toString());
    }

    setFilteredJobs(data);
  }, [selectedStatus, selectedDepartment, jobs]);

 
  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };


  const handleDepartmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDepartment(event.target.value);
  };

 
  const columnHelper = createColumnHelper<JobOpeningType>();
  const columns: ColumnDef<JobOpeningType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => <TableHeader>ID</TableHeader>,
      cell: (info) => (
        <TableCell>{info.getValue()}</TableCell>
      ),
    }),
    columnHelper.accessor("title", {
      id: "job_title",
      header: () => <TableHeader>JOB TITLE</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("location", {
      id: "job_location",
      header: () => <TableHeader>LOCATION</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("employment_type", {
      id: "employment_type",
      header: () => <TableHeader>EMPLOYMENT TYPE</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => <TableHeader>CREATED AT</TableHeader>,
      cell: (info) => <TableCell>{info.getValue().toString().split("T")[0]}</TableCell>,
    }),
    columnHelper.accessor("slug", {
      id: 'view',
      header: () => <TableHeader>VIEW</TableHeader>,
      cell: (info) => {
        const slug = info.getValue();
        return slug ? (
          <Link to={`/careers/job/${slug}`}>
            <TableCell className="!min-w-0 !pr-0 !w-max">
              <MdVisibility className="h-6 w-6 text-gray-600" aria-label="View Job Details" />
            </TableCell>
          </Link>
        ) : (
          <TableCell className="!min-w-0 !pr-0 !w-max">
            <MdVisibility className="h-6 w-6 text-gray-600 opacity-50 cursor-not-allowed" aria-label="No Job Details Available" />
          </TableCell>
        );
      },
    }),
  ];

  return (
    <Table<JobOpeningType> tableData={filteredJobs} columns={columns} tableHeading="Open Jobs">
      <div className="flex justify-end items-center space-x-4 mb-4">
        <SelectField
          options={ACTIVE_STATUS_OPTIONS}
          value={selectedStatus}
          onChange={handleStatusChange}
          extra="!w-full !max-w-xs"
        />
        
        <SelectField
          options={departmentOptions}
          value={selectedDepartment}
          onChange={handleDepartmentChange}
          extra="!w-full !max-w-xs"
        />
      </div>
    </Table>
  );
};

export default JobsTable;
