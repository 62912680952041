const Forbidden = () => {
  return (
    <div className="not-found flex flex-col items-center justify-center h-screen bg-gray-100 dark:bg-gray-800">
      <h1 className="text-4xl font-bold text-gray-700 dark:text-gray-200">403 Forbidden</h1>
      <p className="text-lg mt-4 text-gray-500 dark:text-gray-300">You do not have permission to access this page.</p>
      <a href="/auth/sign-in" className="mt-6 inline-flex items-center px-4 py-2 rounded-md bg-blue-600 hover:bg-blue-700 text-white font-bold">
        Go to Sign In Page
        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 -mr-1 w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7 -7l7 7" />
        </svg>
      </a>
    </div>
  );
};

export default Forbidden;
