import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { EmployeeLeavesDetailsType } from "types/leaves-types";
import Table from "components/Table";

interface LeavesStatusTablePropsType {
  tableData: EmployeeLeavesDetailsType[] | [];
  tableHeading: string;
}

const LeavesStatusTable = (props: LeavesStatusTablePropsType) => {
  const {tableData, tableHeading } = props;
  const columnHelper = createColumnHelper<EmployeeLeavesDetailsType>();
  const columns: ColumnDef<EmployeeLeavesDetailsType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("leave_type_description", {
      id: "leave_type_description",
      header: () => (
        <TableHeader>TYPE</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("leave_status", {
      id: "leave_status",
      header: () => (
        <TableHeader>STATUS</TableHeader>
      ),
      cell: (info) => (
        info.getValue() === "A" ? (
          <TableCell>
            <span className="bg-green-200 text-green-700 px-2 py-1 w-max rounded-xl">
              Approved
            </span>
          </TableCell> 
        ) : info.getValue() === "P" ? (
          <TableCell>
            <span className="bg-gray-200 text-gray-700 px-2 py-1 w-max rounded-xl">
              Pending
            </span>
          </TableCell> 
        ) : info.getValue() === "D" ? (
          <TableCell>
            <span className="bg-red-200 text-red-700 px-2 py-1 w-max rounded-xl">
              Declined
            </span>
          </TableCell> 
        ) : (
          <TableCell>
            Error
          </TableCell>
        )
      ),
    }),
    columnHelper.accessor("start_date", {
      id: "start_date",
      header: () => (
        <TableHeader>START DATE</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("end_date", {
      id: "end_date",
      header: () => (
        <TableHeader>END DATE</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("reason", {
      id: "reason",
      header: () => (
        <TableHeader>REASON</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("comments", {
      id: "comments",
      header: () => (
        <TableHeader>COMMENTS</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
  ];

  return (
    <Table<EmployeeLeavesDetailsType> tableData={tableData}  columns={columns} tableHeading={tableHeading} />
  );
};

export default LeavesStatusTable;
