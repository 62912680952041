import { useState, useEffect } from "react";
import { JobApplicationType } from "types/careers-types";
import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import { Link } from "react-router-dom";
import { MdVisibility } from "react-icons/md";
import Table from "components/Table";
import SelectField from "components/SelectField";

interface JobApplicationsTableProps {
  jobApplications: JobApplicationType[] | [];
}

const JOB_STATUS_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'New', value: 'new' },
  { label: 'In Review', value: 'in-review' },
  { label: 'Shortlisted', value: 'shortlisted' },
  { label: 'Interviewed', value: 'interviewed' },
  { label: 'Offered', value: 'offered' },
  { label: 'Rejected', value: 'rejected' },
];

const JobApplicationsTable = (props: JobApplicationsTableProps) => {
  const { jobApplications } = props;
  const [selectedStatus, setSelectedStatus] = useState<string>('all');
  const [selectedJobOpening, setSelectedJobOpening] = useState<string>('all');
  const [filteredApplications, setFilteredApplications] = useState<JobApplicationType[]>(jobApplications);

  const uniqueJobOpenings = Array.from(new Set(jobApplications.map(app => app.job_opening)));
  const jobOpeningOptions = [
    { label: 'All', value: 'all' },
    ...uniqueJobOpenings.map(jo => ({ label: jo, value: jo })),
  ];

  useEffect(() => {
    console.log(jobApplications, "Job Applications Data");
  }, [jobApplications]);

  useEffect(() => {
    const filteredData = jobApplications.filter(application => {
      const statusMatch = selectedStatus === 'all' || application.status === selectedStatus;
      const jobOpeningMatch = selectedJobOpening === 'all' || application.job_opening === selectedJobOpening;
      return statusMatch && jobOpeningMatch;
    });
    setFilteredApplications(filteredData);
  }, [selectedStatus, selectedJobOpening, jobApplications]);

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
  };

  const handleJobOpeningChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedJobOpening(event.target.value);
  };

  const columnHelper = createColumnHelper<JobApplicationType>();
  const columns: ColumnDef<JobApplicationType, any>[] = [
    columnHelper.accessor("job_opening", {
      id: "job_opening",
      header: () => <TableHeader>VIEW JOB OPENING</TableHeader>,
      cell: (info) => (
        <Link to={`/careers/job/${info.getValue()}`}>
          <TableCell>
            <MdVisibility className="h-6 w-6 text-gray-600" />
          </TableCell>
        </Link>
      ),
    }),
    columnHelper.accessor("applicant.id", {
      id: "applicant.id",
      header: () => <TableHeader>APPLICANT ID</TableHeader>,
      cell: (info) => (
        <Link to={`/careers/applicant/${info.getValue()}`}>
          <TableCell>
            <MdVisibility className="h-6 w-6 text-gray-600" />
          </TableCell>
        </Link>
      ),
    }),
    columnHelper.accessor("applicant.name", {
      id: "applicant.name",
      header: () => <TableHeader>APPLICANT NAME</TableHeader>,
      cell: (info) => <TableCell>{info.getValue()}</TableCell>,
    }),
    columnHelper.accessor("resume", {
      id: "resume",
      header: () => <TableHeader>RESUME</TableHeader>,
      cell: (info) => (
        <TableCell>
          <a href={info.getValue()} target="_blank" rel="noopener noreferrer">
            View Resume
          </a>
        </TableCell>
      ),
    }),
    columnHelper.accessor("applied_at", {
      id: "applied_at",
      header: () => <TableHeader>APPLIED AT</TableHeader>,
      cell: (info) => {
        const date = new Date(info.getValue());
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return <TableCell>{formattedDate}</TableCell>;
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => <TableHeader>VIEW</TableHeader>,
      cell: (info) => (
        <Link to={`/careers/job-application/${info.getValue()}`}>
          <TableCell className="!w-max !min-w-0 !pr-0">
            <MdVisibility className="h-6 w-6 text-gray-600" />
          </TableCell>
        </Link>
      ),
    }),
  ];

  return (
    <Table<JobApplicationType>
      tableData={filteredApplications}
      columns={columns}
      tableHeading="Job Applications"
    >
      <div className="flex justify-end items-center space-x-4">
        <SelectField
          options={JOB_STATUS_OPTIONS}
          value={selectedStatus}
          onChange={handleStatusChange}
          extra="!w-full !max-w-xs"
          label="Filter by Status"
        />
        <SelectField
          options={jobOpeningOptions}
          value={selectedJobOpening}
          onChange={handleJobOpeningChange}
          extra="!w-full !max-w-xs"
          label="Filter by Job"
        />
      </div>
    </Table>
  );
};

export default JobApplicationsTable;
