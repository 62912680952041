import { Link } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { PayslipType } from "types/payroll-types";
import TableHeader from "components/TableHeader";
import TableCell from "components/TableCell";
import Table from "components/Table";
import { formatAmount } from "utils/utilityFunctions";

interface PayslipsTableType {
  tableData: PayslipType[],
  tableName: string,
  ownPayslips?: boolean,
}

function PayslipsTable(props: PayslipsTableType) {
  const { tableData, tableName, ownPayslips } = props;

  const columnHelper = createColumnHelper<PayslipType>();
  const columns:ColumnDef<PayslipType, any>[] = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <TableHeader>ID</TableHeader>
      ),
      cell: (info) => (
        <Link to={ownPayslips? `/my-payslip/${info.row.original.id}`:`/payslip/${info.row.original.id}`} className="text-sm underline font-bold text-blue-700 dark:text-white">
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor("employee_details.first_name", {
      id: "first_name",
      header: () => (
        <TableHeader>First Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("employee_details.last_name", {
      id: "last_name",
      header: () => (
        <TableHeader>Last Name</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <TableHeader>Status</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("net_salary", {
      id: "net_salary",
      header: () => (
        <TableHeader>Net Salary</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {formatAmount(info.getValue())}
        </TableCell>
      ),
    }),
    columnHelper.accessor("period_start", {
      id: "period_start",
      header: () => (
        <TableHeader>Period Start</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
    columnHelper.accessor("period_end", {
      id: "period_end",
      header: () => (
        <TableHeader>Period End</TableHeader>
      ),
      cell: (info) => (
        <TableCell>
          {info.getValue()}
        </TableCell>
      ),
    }),
  ];
  
  return (
    <Table<PayslipType> tableData={tableData} columns={columns} tableHeading={tableName} />
  )
}

export default PayslipsTable;
