import { useEffect, useState } from "react";
import { getAllEmployees } from "api/apiHelper";
import { getClausesAPI } from "api/apiHelper";
import { createContractAPI } from "api/apiHelper";
import ContractClausePicker from "./ContractClausePicker";
import Card from "components/Card";
import { toast } from "react-toastify";
import ErrorBox from "components/ErrorBox";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { ContractType } from "pages/contracts/ShowContract";
import Backdrop from "components/Backdrop";
import { usePermissions } from "app/hooks";


export interface ClauseType {
  id: number;
  clause_text: string;
  legal_text: string;
}

const CreateContract: React.FC = () => {
  const [selectEmployees, setSelectEmployees] = useState<EmployeeType[]>([]);
  const [selectClauses, setSelectClauses] = useState<ClauseType[]>([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [cnic, setCNIC] = useState<string>("");
  const [selectedClauses, setSelectedClauses] = useState<ClauseType[]>([]);
  const [allClauses, setAllClauses] = useState<ClauseType[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const permissions = usePermissions() || [];
  const navigate = useNavigate();

  const handleCancel = () => {
    setSelectedEmployeeId("");
    setFirstName("");
    setLastName("");
    setCNIC("");
    setSelectedClauses([]);
    setError("");
  };


  const getEmployees = async () => {
    if (permissions && permissions.includes('employees.view_employee')){
      try {
        const response: AxiosResponse<EmployeeType[]> = await getAllEmployees();
        const employees: EmployeeType[] = response.data;
        setSelectEmployees(employees);
      }
      catch (error: any) {
        console.log(error);
        setError(error.message);
      }
    }
  };
  const fetchAllClauses = async () => {
    if (permissions && permissions.includes('contracts.view_contractclause')){
      try {
        const response: AxiosResponse<ClauseType[]> = await getClausesAPI();
        const clauses: ClauseType[] = response.data;
        setAllClauses(clauses);
        setIsLoading(false);
      } catch (error: any) {
        console.log(error);
        setError(error.message);
        setIsLoading(false);
      }
    }
    else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
    fetchAllClauses();
  }, []);
  
  const handleEmployeeChange = (selectedId: string) => {
    setSelectedEmployeeId(selectedId);
    const selectedEmployee = selectEmployees.find((employee) => employee.id.toString() === selectedId);

    if (selectedEmployee) {
      setFirstName(selectedEmployee.first_name);
      setLastName(selectedEmployee.last_name);
      setCNIC(selectedEmployee.CNIC);
      setSelectClauses(allClauses);
      setSelectedClauses([]);
      setError("");
    } else {
      console.error("Invalid employee ID selected");
      setError("Invalid employee selected");
      setSelectedEmployeeId("");
      setFirstName("");
      setLastName("");
      setCNIC("");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const notification = toast.loading("Creating Contract...");
    
    const contractData = {
      employee: selectedEmployeeId,
      clauses_in_contract: selectedClauses.map(clause => clause.id)
    };

    try {
      const response: AxiosResponse<ContractType> = await createContractAPI(contractData);
      console.log("Contract created successfully:", response.data);
      toast.update(notification, {
        render: "Contract created successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      navigate(response.data?.id ? `/employee/contract/${response.data.id}` : "/");
    } catch (error) {
      console.error("Error creating contract:", error);
      toast.update(notification, {
        render: "Failed to create contract",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleClauseClick = (clauseId: number, isFromAvailable: boolean) => {
    if (isFromAvailable) {
      const selectedClause = selectClauses.find((clause) => clause.id === clauseId);
      setSelectedClauses([...selectedClauses, selectedClause]);
      setSelectClauses(selectClauses.filter((clause) => clause.id !== clauseId));
    } else {
      const selectedClause = selectedClauses.find((clause) => clause.id === clauseId);
      setSelectClauses([...selectClauses, selectedClause]);
      setSelectedClauses(selectedClauses.filter((clause) => clause.id !== clauseId));
    }
  };

  return (
    isLoading? (
      <Backdrop show={isLoading}/>
    ):(
      permissions && permissions.includes('employees.view_employee') && permissions.includes('contracts.view_contractclause')?(
      <Card extra={"w-full h-full pb-10 p-4"}>
        {/* Header */}
        <div className="mb-8 mt-2 w-full">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Create an Employee Contract        
          </h4>
        </div>
        { error && <ErrorBox errorMessage={error} />}
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <div className="sm:col-span-2 text-gray-900 dark:text-white">
                <label htmlFor="select_employee" className="block text-sm font-medium leading-6 ">Select Employee</label>
                <div className="mt-2">
                  <select
                    id="select_employee"
                    name="select_employee"
                    autoComplete="select_employee"
                    onChange={(event) => handleEmployeeChange(event.target.value)} 
                    value={selectedEmployeeId}
                    className="
                      block
                      w-full
                      rounded-md
                      border-0
                      py-1.5
                      shadow-sm
                      ring-1
                      ring-inset
                      dark:bg-navy-700
                      ring-gray-300
                      focus:ring-2
                      focus:ring-inset
                      focus:ring-indigo-600
                      sm:max-w-xs
                      sm:text-sm
                      sm:leading-6
                    "
                  >
                    <option value={0}>Select an employee</option> {/* Set default value */}
                    {selectEmployees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.first_name} {employee.last_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6">First name</label>
                  <div className="mt-2">
                    <input type="text" name="first-name" id="first-name" disabled autoComplete="given-name" value={firstName} className="dark:bg-navy-700 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6">Last name</label>
                  <div className="mt-2">
                    <input type="text" name="last-name" disabled id="last-name" autoComplete="family-name" value={lastName} className="dark:bg-navy-700 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="cnic" className="block text-sm font-medium leading-6">CNIC</label>
                  <div className="mt-2">
                    <input id="cnic" name="cnic" type="text" disabled value={cnic} autoComplete="cnic" className="dark:bg-navy-700 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contract-form">
            {selectedEmployeeId !== "" ? (
              <ContractClausePicker selectClauses={selectClauses} selectedClauses={selectedClauses} onClauseClick={handleClauseClick} />
            ) : (
              <p className="text-base font-semibold leading-7">
                Select an employee to choose contract clauses.
              </p>
            )}
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button onClick={handleCancel} type="button" className="text-sm font-semibold leading-6 ">Cancel</button>
            <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
          </div>
        </form>
      </Card>
      ): (
        <ErrorBox errorMessage="Insufficient privileges."/>
      )
    )
  );
}

export default CreateContract;
