import { useEffect, useState } from 'react';
import Card from 'components/Card';
import Checkbox from 'components/Checkbox';
import InputField from 'components/InputField';
import { FullFormType, QuestionType, QuestionOptionType } from 'pages/appraisals/AppraisalsForm';
import Button from "components/Button";
import { postAppraisalForm } from "api/apiHelper";
import { toast } from "react-toastify";

interface AppraisalFormComponentPropsType {
  formData: FullFormType | null;
}

interface FormSubmissionData {
  formId: number;
  answers: {
    [questionId: number]: {
      textAnswer?: string;
      selectedOptions?: number[];
    };
  };
}

const AppraisalFormComponent = (props: AppraisalFormComponentPropsType) => {
  const { formData } = props;

  const [formSubmissionData, setFormSubmissionData] = useState<FormSubmissionData>({
    formId: formData?.id || null,
    answers: {},
  });

  useEffect(() => {
    setFormSubmissionData((prevData) => ({
      ...prevData,
      formId: formData?.id || 0,
    }))
  }, [formData])

  const handleTextAnswerChange = (questionId: number, value: string) => {
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          textAnswer: value,
        },
      },
    }));
  };

  
  const handleRatingChange = (questionId: number, optionValue: string) => {
    const optionId = formData?.questions.find((q) => q.id === questionId)?.options.find((o) => o.option_text === optionValue)?.id;
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          selectedOptions: optionId ? [optionId] : [],
        },
      },
    }));
  };

  const handleSelectChange = (questionId: number, optionValue: string) => {
    const optionId = formData?.questions.find((q) => q.id === questionId)?.options.find((o) => o.option_text === optionValue)?.id;
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          selectedOptions: optionId ? [optionId] : [],
        },
      },
    }));
  };

  const handleMultiSelectChange = (questionId: number, optionValue: string, isChecked: boolean) => {
    const optionId = formData?.questions.find((q) => q.id === questionId)?.options.find((o) => o.option_text === optionValue)?.id;
    setFormSubmissionData((prevData) => ({
      ...prevData,
      answers: {
        ...prevData.answers,
        [questionId]: {
          selectedOptions: isChecked && optionId 
            ? [...(prevData.answers[questionId]?.selectedOptions || []), optionId]
            : (prevData.answers[questionId]?.selectedOptions || []).filter((value) => value !== optionId),
        },
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const notification = toast.loading("Submitting Form...");

    try {
      await postAppraisalForm(formSubmissionData);
      toast.update(notification, {
        render: "Form submitted successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
      handleReset();
    } catch (error) {
      console.log(error);
      toast.update(notification, {
        render: "Failed to submit Form",
         type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  const handleReset = () => {
    setFormSubmissionData({
      formId: formData?.id || 0,
      answers: {},
    });
  };

  const handleCheckboxChange = (questionId: number, optionValue: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    handleMultiSelectChange(questionId, optionValue, event.target.checked);
  };  

  const renderInputField = (question: QuestionType, index: number) => {
    switch (question.question_type) {
      case 'text':
        return (
          <InputField
            id="Answer"
            type="text"
            placeholder="Enter your answer here..."
            extra="w-full"
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleTextAnswerChange(question.id, e.target.value);
            }}
            value={formSubmissionData.answers[question.id]?.textAnswer || ''}
            required
          />
        );
      case 'rating':
        return (
          <div className="mt-2">
            {question.options.map((option: QuestionOptionType, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center gap-2 mb-2">
                <input
                  type="radio"
                  id={`rating-${index}-${optionIndex}`}
                  name={`rating-${index}`} 
                  value={option.option_text}
                  onChange={() => {
                    handleRatingChange(question.id, option.option_text);
                  }}
                />
                <label htmlFor={`rating-${index}-${optionIndex}`}>{option.option_text}</label>
              </div>
            ))}
          </div>
        );
      case 'select':
        return (
          <div className="mt-2">
            {question.options.map((option: QuestionOptionType, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center gap-2 mb-2">
                <input
                  type="radio"
                  id={`select-${index}-${optionIndex}`}
                  name={`select-${index}`}
                  value={option.option_text}
                  onChange={() => {
                    handleSelectChange(question.id, option.option_text);
                  }}
                />
                <label htmlFor={`select-${index}-${optionIndex}`}>{option.option_text}</label>
              </div>
            ))}
          </div>
        );
      case 'multi-select':
        return (
          <div className="mt-2">
            {question.options.map((option: QuestionOptionType, optionIndex: number) => (
              <div key={optionIndex} className="flex items-center gap-2 mb-2">
                <Checkbox 
                  id={`multi-select-${index}-${optionIndex}`} 
                  value={option.option_text}
                  onChange={handleCheckboxChange(question.id, option.option_text)}
                />
                <label htmlFor={`multi-select-${index}-${optionIndex}`}>{option.option_text}</label>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card extra="w-full h-full pb-10 p-4">
      <div className="mb-8 mt-2 w-full">
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
          {formData?.title}
        </h4>
      </div>

      <form className="flex flex-col gap-6" onSubmit={handleSubmit} onReset={handleReset}>
        {formData?.questions.map((question: QuestionType, index: number) => (
          <div key={index} className="flex flex-col items-start gap-2">
            <span className="text-lg font-semibold">{`${index + 1}. ${question.question_text}`}</span>
            {renderInputField(question, index)}
          </div>
        ))}

        <div className="flex justify-end gap-2">
          <Button type="reset" varient="secondary" className="w-max">Cancel</Button>
          <Button type="submit" varient="primary" className="w-max">Submit</Button>
        </div>
      </form>
    </Card>
  );
};

export default AppraisalFormComponent;
