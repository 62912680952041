import React from "react";

interface TableHeaderPropsType {
  children: React.ReactNode;
  className?: string;
}

const TableHeader = (props: TableHeaderPropsType) => {
  const { children, className } = props;
  return (
    <p className={`text-sm font-bold text-gray-600 dark:text-white ${className}`}>
      {children}
    </p>
  );
};

export default TableHeader;
